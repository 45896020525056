<template>
  <div class="bg-white">
    <wizard-header
      title="Create a Study Site"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard :currentStep="step" wizardType="sites"></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <basic-information
        :isProtocol="isProtocolView"
        :basic="site.basic"
        v-show="step === 0"
        @nextStep="step++"
      />
      <site-address
        :siteAddress="site.siteAddress"
        v-show="step === 1"
        @nextStep="step++"
        @back="step--"
      />
      <overview
        :site="site"
        :addingSite="submitting"
        :isProtocolView="isProtocolView"
        v-if="step === 2"
        @back="step--"
        @createSite="postSite"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      title="Create new Site"
      footer-class="justify-content-center"
      header-class="justify-content-center"
      title-tag="h3"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center px-5">
        {{ $t('exit_create_site') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name: isProtocolView
                ? $route.name === 'protocol-create-site'
                  ? 'protocol-sites'
                  : `${$route.meta.parent}-protocol-sites`
                : `${$route.meta.parent}-sites`,
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BasicInformation from '@/components/Sites/BasicInformation';
import SiteAddress from '@/components/Sites/Address';
import Overview from '@/components/Sites/Overview';
import WizardHeader from '@/components/WizardHeader';
import { createSite, fetchSites, postSiteContact } from '@/api/site';
import { mapState } from 'vuex';

export default {
  components: {
    BasicInformation,
    SiteAddress,
    Overview,
    WizardHeader,
  },
  data() {
    return {
      showConfirmationModal: false,
      isTopLevelProtocol: this.$route.name === 'protocol-create-site',

      submitting: false,
      step: 0,
      site: {
        basic: {
          description: '',
          protocolId: '',
          name: '',
        },
        siteAddress: {
          address: '',
          city: '',
          countryId: '',
          postalCode: '',
        },
      },
    };
  },
  created() {
    if (this.isProtocolView) this.site.basic.protocolId = this.protocol.id;

    this.setBreadCrumbData({
      'client-create-site': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.$t('site_create_new'),
          active: true,
        },
      ],
      'study-create-site': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },
        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.$t('site_create_new'),
          active: true,
        },
      ],

      'protocol-create-site': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },
        {
          text: this.protocol?.name,
          to: { name: 'view-protocol' },
        },
        {
          text: this.$t('site_create_new'),
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapState({
      study: (state) => state.studies.selectedStudy,
      protocol: (state) => state.protocols.selectedProtocol,
      selectedClient: (state) => state.clients.selectedClient,
      isProtocolView: (state) => state.sites.isProtocolView,
    }),
  },
  methods: {
    async postSite() {
      this.submitting = true;
      const {
        id: protocolId,
        study: { clientId, id: studyId },
      } = this.protocol;
      const basic = { ...this.site.basic };
      if (!basic.description.trim()) delete basic.description;
      delete basic.protocolId;

      try {
        let {
          data: { id: newSiteId },
        } = await createSite({
          clientId,
          studyId,
          protocolId,
          payload: basic,
        });

        if (!newSiteId) {
          const payload = { 'filter[name]': basic.name };
          const { data } = await fetchSites(payload);
          newSiteId = data[0]?.site?.id;
        }

        if (newSiteId) {
          const { data: contact } = await postSiteContact(
            clientId,
            studyId,
            protocolId,
            newSiteId,
            this.site.siteAddress
          );

          contact.id
            ? (this.$toast.info(this.$t('toast_site_new_created')),
              this.$router.push({
                name: this.isTopLevelProtocol
                  ? 'protocol-sites'
                  : this.isProtocolView
                  ? `${this.$route.meta.parent}-protocol-sites`
                  : `${this.$route.meta.parent}-sites`,

                params: { isNewlyCreated: true, siteId: newSiteId },
              }))
            : null;
        } else {
          this.$toast.error(this.$t('error_something_went_wrong'));
        }
      } catch (error) {
        this.$toast.error(this.$t(error?.data?.msg));
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style></style>
