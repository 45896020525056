<template>
  <div>
    <h2>{{ $t('client_address_invoice') }}</h2>

    <b-row>
      <b-col cols="12">
        <label>{{ $t('address') }}</label>
        <b-form-input
          v-model="invoice.address"
          :placeholder="$t('address')"
          maxLength="150"
          data-testid="address-input"
        ></b-form-input>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <label>{{ $t('city') }}</label>
            <b-form-input
              v-model="invoice.city"
              :placeholder="$t('city')"
              data-testid="city-input"
              maxLength="150"
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('country') }}</label>
            <v-select
              v-model="invoice.countryId"
              :placeholder="$t('country')"
              :options="countries"
              :reduce="(country) => country.id"
              label="shortName"
              :disabled="countries.length === 0"
              id="countries"
            />
          </b-col>
          <b-col cols="4">
            <label>{{ $t('postal_code') }}</label>
            <b-form-input
              v-model="invoice.postalCode"
              :placeholder="$t('postal_code')"
              maxLength="10"
              data-testid="postal-input"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="$emit('nextStep', invoice)"
          data-testid="next-button"
          :disabled="!checkInputsLength"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      invoice: {
        type: 'invoice',
        address: '',
        city: '',
        countryId: '',
        postalCode: '',
      },
      isEdit: this.$route.name === 'edit-client',
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.resources.countries,
      client: ({ clients }) => clients.selectedClient,
    }),
    checkInputsLength() {
      const { address, city, postalCode } = this.invoice;
      return (
        address.length < 151 && city.length < 151 && postalCode.length < 11
      );
    },
  },
  mounted() {
    if (this.isEdit) {
      const { address, city, countryId, postalCode } = this.client.invoice;
      this.invoice = {
        type: 'invoice',
        address,
        city,
        countryId,
        postalCode,
      };
    }
  },
};
</script>

<style></style>
