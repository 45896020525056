<template>
  <div>
    <h2 class="">{{ $t('general_information') }}</h2>
    <b-row>
      <b-col cols="6">
        <label>{{ $t('client') }}</label>
        <b-form-input
          v-model="client.legalName"
          disabled
          data-testid="client-name"
        ></b-form-input>
      </b-col>
      <b-col cols="6">
        <label>{{ $t('study_name') }}</label>
        <b-form-input
          v-model="name"
          placeholder="Study Name (Unique)"
          maxLength="100"
          data-testid="study-name-input"
          :disabled="!hasAppropriateProtocols && isEdit"
        ></b-form-input>
      </b-col>
      <b-col cols="12">
        <label>{{ $t('description') }}</label>
        <textarea
          rows="4"
          v-model="description"
          class="form-control form-control"
          :placeholder="$t('description_tip')"
          data-testid="description-input"
        />
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          rounded
          type="primary"
          :disabled="!validForm || validating"
          @click="shouldValidate ? handleNextClick() : emitNext()"
          data-testid="next-button"
        >
          {{ $t('next') }}
          <b-spinner v-if="validating" small></b-spinner>
          <arrow-right v-else />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fetchClientStudies } from '@/api/study';
import { mapGetters, mapState } from 'vuex';
import { fetchClientBasicInfo } from '@/api/client';

export default {
  data() {
    return {
      validating: false,
      name: '',
      description: '',
      isEdit: this.$route.name.includes('edit'),
      client: {},
    };
  },
  computed: {
    ...mapState({
      selectedClient: ({ clients }) => clients.selectedClient,
      study: ({ studies }) => studies.selectedStudy,
    }),
    ...mapGetters({
      hasAppropriateProtocols: 'studies/hasAppropriateProtocols',
    }),
    validForm() {
      return !!this.name;
    },
    shouldValidate() {
      return !(this.study.name === this.name && this.isEdit);
    },
  },
  mounted() {
    this.isEdit &&
      ((this.name = this.study.name),
      (this.description = this.study.description));
  },
  created() {
    this.getClientBasicInfo();
  },

  methods: {
    emitNext() {
      const { name, description } = this;
      this.$emit('nextStep', { name, description });
    },
    async handleNextClick() {
      this.validating = true;
      try {
        const res = await fetchClientStudies({
          'filter[name]': this.name,
          'filter[client_id]': this.$route.params.id,
        });
        res?.data?.length === 0
          ? this.emitNext()
          : this.$toast.error(this.$t('error_study_name_in_use'));
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.validating = false;
      }
    },
    async getClientBasicInfo() {
      try {
        let clientId = this.isEdit
          ? this.study.clientId
          : this.$route.params.id;
        const { data } = await fetchClientBasicInfo(clientId);
        this.client = data;
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
  },
};
</script>

<style></style>
