<template>
  <div class="mb-3">
    <p class="avenir-bold p-3 bg-white mb-0">{{ $t('studies') }}</p>
    <p v-if="firstLoading" class="p-3 bg-white">{{ $t('loading') }}</p>
    <StudiesTable
      v-else
      :studiesListTableData="studies"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import { mapActions, mapState } from 'vuex';
import { paginationDefaults } from '@/constants/pagination-constants';
import StudiesTable from '@/components/Tables/StudiesTable.vue';
import { studyListDataTableObjectMapper } from '@/object-mappers/study.object-mapper';

export default {
  name: 'DashboardStudyList',
  components: { StudiesTable },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      studies: ({ studies }) => studyListDataTableObjectMapper(studies.studies),
      storePagination: ({ protocols }) => protocols.pagination,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('studies');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
      pagination: {
        pageSize: paginationDefaults.dashboardPageSize,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
    };
  },
  created() {
    this.getListData('studies');
  },
  methods: {
    ...mapActions({
      fetchListData: 'studies/fetchStudies',
      setStudyParams: 'studies/setStudyParams',
    }),
    goToDetail(study) {
      this.setStudyParams({
        clientId: study.clientId,
        studyId: study.id,
      });
      this.$emit('goToDetail');
    },
  },
};
</script>
