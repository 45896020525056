<template>
  <div class="bg-white shadow p-3">
    <b-row class="border-bottom mx-0">
      <b-col md="12" lg="6">
        <p class="avenir-bold mb-0 text-lg">
          {{
            surveyType === 'one'
              ? this.$t('relapse_followup_first_survey')
              : this.$t('relapse_followup_second_survey')
          }}
        </p>
      </b-col>
      <b-col
        md="12"
        lg="6"
        class="d-flex flex-md-column justify-content-sm-around align-items-center"
      >
        <app-badge medium v-if="completedAtDate" variant="grey" class="mb-2">
          {{ getDatafromTimestamp(completedAtDate) }}
        </app-badge>

        <app-badge
          medium
          v-if="
            moment(scheduledDate).isSameOrAfter(moment(Date.now())) &&
            !completedAtDate &&
            !dropped
          "
          :variant="isParticipantDroppedOut ? 'red' : 'orange'"
          class="mb-2"
        >
          <span class="mr-2">{{
            isParticipantDroppedOut ? $t('overdue') : $t('planned')
          }}</span
          >{{ moment(scheduledDate).format('DD/MM/YYYY') }}
        </app-badge>

        <app-badge
          medium
          v-if="
            moment(Date.now()).isBefore(moment(scheduledDate).add(7, 'days')) &&
            moment(Date.now()).isAfter(scheduledDate) &&
            !completedAtDate
          "
          variant="green"
          class="mb-2"
        >
          <span class="mr-2">{{ $t('due') }} </span
          >{{ moment(scheduledDate).add(7, 'days').format('DD/MM/YYYY') }}
        </app-badge>

        <app-badge
          medium
          v-if="
            moment(Date.now()).isAfter(moment(scheduledDate).add(7, 'days')) &&
            !completedAtDate
          "
          variant="red"
          class="mb-2"
        >
          <span class="mr-2">{{ $t('overdue') }} </span
          >{{ moment(scheduledDate).add(7, 'days').format('DD/MM/YYYY') }}
        </app-badge>
        <app-badge
          medium
          v-if="
            moment(scheduledDate).isSameOrAfter(moment(Date.now())) &&
            !completedAtDate &&
            dropped
          "
          variant="red"
          class="mb-2"
        >
          <span class="mr-2">{{ $t('overdue') }} </span
          >{{ moment(scheduledDate).add(7, 'days').format('DD/MM/YYYY') }}
        </app-badge>
      </b-col>
    </b-row>
    <b-row class="mx-0 mt-4">
      <b-col cols="12" class="px-2 mb-4">
        <p class="mb-0 text-grey">{{ $t('have_you_recovered') }}</p>
        <p class="mb-0 mt-1" v-if="completedAtDate">
          {{ $t(recoveryStatus.name) }}
        </p>
      </b-col>
      <b-col cols="12" class="px-2 mb-4">
        <p class="mb-0 text-grey">{{ $t('how_long_did_recovery_take') }}</p>
        <p class="mb-0 mt-1" v-if="completedAtDate">
          {{ $t(recoveryDuration.name) }}
        </p>
      </b-col>
      <b-col cols="12" class="px-2 mb-4">
        <p class="mb-0 text-grey">
          {{ $t('did_you_get_corticoids_treatment') }}
        </p>
        <p class="mb-0 mt-1" v-if="completedAtDate">
          {{ gotCorticoids ? this.$t('yes') : this.$t('no') }}
        </p>
      </b-col>
      <b-col cols="12" class="px-2 mb-4">
        <p class="mb-0 text-grey">
          {{ $t('did_you_experience_impact_apply_all') }}
        </p>

        <div v-if="experienceImpacts.length > 0 && completedAtDate">
          <p v-for="(impact, i) in experienceImpacts" :key="i" class="mb-0">
            {{ $t(impact.experienceImpact.name) }}
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppBadge from '../AppBadge/AppBadge.vue';
import { unix_timestampToYYYY_MM_DD_hh_mm } from '../../utils/dateTransform';
import { mapGetters, mapState } from 'vuex';
export default {
  components: { AppBadge },
  name: 'follow-up-survey',
  props: {
    relapse: Object,
    surveyType: String,
  },
  data() {
    return {
      badgeDate: '',
      badgeText: '',
    };
  },

  computed: {
    ...mapGetters({
      isParticipantDroppedOut: 'participants/isParticipantDroppedOut',
      dropped: 'participants/isParticipantDroppedOut',
    }),
    ...mapState({
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    scheduledDate() {
      return this.surveyType === 'one'
        ? this.relapse.surveyOneScheduledDate
        : this.relapse.surveyTwoScheduledDate;
    },
    completedAtDate() {
      return this.surveyType === 'one'
        ? this.relapse.surveyOneCompletedAt
        : this.relapse.surveyTwoCompletedAt;
    },
    recoveryStatus() {
      return this.surveyType === 'one'
        ? this.relapse.surveyOneRecoveryStatus
        : this.relapse.surveyTwoRecoveryStatus;
    },
    recoveryDuration() {
      return this.surveyType === 'one'
        ? this.relapse.surveyOneRecoveryDuration
        : this.relapse.surveyTwoRecoveryDuration;
    },
    gotCorticoids() {
      return this.surveyType === 'one'
        ? this.relapse.surveyOneGotCorticoids
        : this.relapse.surveyTwoGotCorticoids;
    },
    experienceImpacts() {
      return this.surveyType === 'one'
        ? this.relapse.surveyOneExperienceImpacts
        : this.relapse.surveyTwoExperienceImpacts;
    },
  },
  methods: {
    getDatafromTimestamp(date) {
      return unix_timestampToYYYY_MM_DD_hh_mm(date);
    },
  },
};
</script>

<style scoped lang="scss">
.text-lg {
  font-size: 1.125rem !important;
}
</style>
