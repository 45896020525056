<template>
  <div>
    <div class="text-center" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div :class="isTopLevelParticipant && 'm-5'" v-else>
      <b-row v-if="relapse.participant">
        <b-col>
          <div class="bg-white shadow">
            <b-row class="border-bottom mx-0 py-3 px-2">
              <b-col class="d-flex align-items-center">
                <router-link
                  :to="{
                    name: isTopLevelParticipant
                      ? 'view-participant'
                      : `${$route.meta.parent}-view-participant`,
                    params: {
                      id: relapse.participant.id,
                    },
                  }"
                  class="d-flex"
                >
                  <img
                    :src="require(`@/assets/icons/arrow_forward.svg`)"
                    class="toggle-icon"
                  />
                </router-link>

                <p class="avenir-bold mb-0 ml-3 text-lg">
                  {{ $t('id') }}: {{ relapse.participant.participantDisplayId }}
                </p>
                <span class="opacity-6 ml-2 text-lg"
                  >( {{ $t('relapse_id') }} {{ relapse.displayId }} )</span
                >
              </b-col>
              <b-col>
                <p class="text-right mb-0 text-lg">
                  <span class="mr-2">{{ $t('date_report') }}</span
                  >{{ moment(relapse.reportedDate).format('DD/MM/YYYY') }}
                </p>
              </b-col>
            </b-row>

            <b-row class="mx-2 my-4">
              <b-col>
                <questions-section
                  :title="$t('loss_of_strengths_or_sensitivity')"
                  :isActive="
                    relapse.severityLossSensitivity.name !== statusNone ||
                    relapse.severityLossStrength.name !== statusNone ||
                    relapse.limbs.length !== 0
                  "
                >
                  <b-row
                    class="mt-3 mx-0 border-bottom"
                    v-if="relapse.limbs.length"
                  >
                    <b-col
                      cols="3"
                      class="mb-3 pl-0 font-weight-600 d-flex align-items-center text-sm"
                      v-for="limb in relapse.limbs"
                      :key="limb.id"
                    >
                      <i class="ni ni-check-bold text-primary mr-2"></i>
                      {{ $t(limb.limb.name) }}
                    </b-col>
                  </b-row>

                  <b-row
                    class="mt-3"
                    v-if="
                      relapse.severityLossSensitivity.name !== statusNone ||
                      relapse.severityLossStrength.name !== statusNone ||
                      relapse.limbs.length
                    "
                  >
                    <b-col>
                      <p class="opacity-6 mb-1 text-sm">
                        {{ $t('sensitivity_loss') }}
                      </p>
                      <p
                        class="avenir-bold mb-0 text-sm"
                        :class="
                          getColorClass(relapse.severityLossSensitivity.name)
                        "
                      >
                        {{ relapse.severityLossSensitivity.name }}
                      </p>
                    </b-col>
                    <b-col>
                      <p class="opacity-6 mb-1 text-sm">
                        {{ $t('strength_loss') }}
                      </p>
                      <p
                        class="avenir-bold mb-0 text-sm"
                        :class="
                          getColorClass(relapse.severityLossStrength.name)
                        "
                      >
                        {{ relapse.severityLossStrength.name }}
                      </p>
                    </b-col>
                  </b-row>
                </questions-section>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col>
                <questions-section
                  :title="$t('loss_of_balance_or_coordination')"
                  :isActive="
                    stringToBoolean(relapse.hasLossBalanceCoordination)
                  "
                  :subSection="false"
                />
              </b-col>
            </b-row>

            <b-row class="mx-2 my-4">
              <b-col>
                <questions-section
                  :title="$t('loss_of_vision')"
                  :isActive="
                    stringToBoolean(relapse.hasLossVisionLeftEye) ||
                    stringToBoolean(relapse.hasLossVisionRightEye) ||
                    stringToBoolean(relapse.hasExperiencedDoubleVision)
                  "
                >
                  <b-row
                    class="mt-3 border-bottom mx-0"
                    v-if="
                      relapse.hasLossVisionLeftEye ||
                      relapse.hasLossVisionRightEye ||
                      relapse.hasExperiencedDoubleVision
                    "
                  >
                    <b-col
                      cols="3"
                      class="mb-3 font-weight-600 d-flex align-items-center pl-0 text-sm"
                      v-for="vision in visions"
                      :key="vision.id"
                    >
                      <i
                        class="ni ni-check-bold text-primary mr-2"
                        v-if="relapse[vision.key]"
                      ></i>
                      {{ $t(vision.name) }}
                    </b-col>
                  </b-row>

                  <b-row
                    class="mt-3"
                    v-if="
                      relapse.hasLossVisionLeftEye ||
                      relapse.hasLossVisionRightEye ||
                      relapse.hasExperiencedDoubleVision
                    "
                  >
                    <b-col>
                      <p class="opacity-6 mb-1 text-sm">
                        {{ $t('severity_of_loss_of_vision') }}
                      </p>
                      <p
                        class="avenir-bold mb-0 text-sm"
                        :class="getColorClass(relapse.severityLossVision.name)"
                      >
                        {{ relapse.severityLossVision.name }}
                      </p>
                    </b-col>

                    <b-col>
                      <p class="opacity-6 mb-1 text-sm">
                        {{ $t('severity_of_double_vision') }}
                      </p>
                      <p
                        class="avenir-bold mb-0 text-sm"
                        :class="
                          getColorClass(relapse.severityDoubleVision.name)
                        "
                      >
                        {{
                          relapse.severityDoubleVision
                            ? relapse.severityDoubleVision.name
                            : $t('no')
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </questions-section>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col>
                <questions-section
                  :title="$t('loss_of_memory_or_cognitive')"
                  :isActive="stringToBoolean(relapse.hasLossMemory)"
                  :subSection="false"
                />
              </b-col>
            </b-row>

            <b-row class="mx-2 mt-4 pb-4">
              <b-col>
                <questions-section
                  :title="$t('bladder_or_bowel_issues')"
                  :isActive="relapse.bladderBowelIssues.length !== 0"
                >
                  <b-row class="mt-3" v-if="relapse.bladderBowelIssues.length">
                    <b-col
                      cols="3"
                      class="mb-3 font-weight-600 d-flex align-items-center text-sm"
                      v-for="issue in relapse.bladderBowelIssues"
                      :key="issue.id"
                    >
                      <i class="ni ni-check-bold text-primary mr-2"></i>
                      {{ issue.bladderBowelIssue.name }}
                    </b-col>
                  </b-row>
                </questions-section>
              </b-col>
            </b-row>

            <b-row class="mx-2 mt-4 pb-4" v-if="relapse.ownSymptoms">
              <b-col>
                <div class="bg-white shadow p-3">
                  <b-row class="mx-0 border-bottom">
                    <b-col
                      class="pb-3 px-0 d-flex justify-content-between align-items-center"
                    >
                      <p class="avenir-bold mb-0 text-lg">
                        {{ $t('relapse_participant_reported_symptoms') }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col cols="12">
                      <p>
                        {{ relapse.ownSymptoms }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row
              class="mt-4 mx-2"
              v-if="
                relapse.participant.status.name === 'ACTIVE' &&
                !relapseSchedule.relapseTestSchedules
              "
            >
              <b-col cols="6" class="ml-auto">
                <base-button
                  data-testid="create-relapse-button"
                  type="primary"
                  class="mb-4 d-block ml-auto"
                  rounded
                  @click="
                    $router.push({
                      name:
                        $route.name === 'view-relapse'
                          ? 'create-relapse-schedule'
                          : `${$route.meta.parent}-create-relapse-schedule`,
                    })
                  "
                  v-if="canWriteParticipant"
                  >{{ $t('relapse_schedule_create') }}</base-button
                >
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="6">
          <follow-up-survey :relapse="relapse" surveyType="one" />
        </b-col>
        <b-col cols="6">
          <follow-up-survey :relapse="relapse" surveyType="two" />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <!-- table -->
        <b-col
          v-if="relapse.relapseTestSchedules.length > 0"
          :cols="relapseSchedule.scheduleStartedAt ? 8 : 12"
        >
          <div class="bg-white shadow">
            <b-row
              class="border-bottom collapse-body mx-0 py-3 px-1"
              v-b-toggle.schedule-collapse
            >
              <b-col cols="8">
                <p class="avenir-bold collapse-title mb-0">
                  {{ $t('participant_schedule_relapse') }}
                  <span class="avenir opacity-6"
                    >({{ relapse.displayId }})</span
                  >
                </p>
              </b-col>
              <b-col class="text-right">
                <app-badge
                  large
                  v-if="
                    relapseSchedule.scheduleStartedAt &&
                    moment(Date.now()).isSameOrBefore(
                      moment(relapseSchedule.scheduleEndAt)
                    )
                  "
                  :variant="dropped ? 'grey' : 'success'"
                  >{{ dropped ? $t('completed') : $t('ACTIVE') }}</app-badge
                >
                <app-badge
                  large
                  v-if="!relapseSchedule.scheduleStartedAt"
                  :variant="dropped ? 'red' : 'orange'"
                  >{{ dropped ? $t('deleted') : $t('created') }}</app-badge
                >
                <app-badge
                  large
                  v-if="
                    relapseSchedule.scheduleStartedAt &&
                    moment(Date.now()).isAfter(
                      moment(relapseSchedule.scheduleEndAt)
                    )
                  "
                  variant="grey"
                  >{{ $t('completed') }}</app-badge
                >
              </b-col>
            </b-row>
            <b-collapse id="schedule-collapse" visible>
              <b-row class="mt-3 px-1 mx-0 pb-4">
                <b-col>
                  <relapse-schedule :schedules="relapseSchedule" />
                  <div
                    class="mt-5 d-flex justify-content-end"
                    v-if="!relapseSchedule.scheduleStartedAt && !dropped"
                  >
                    <b-button
                      variant="outline-primary"
                      class="rounded-pill mr-3"
                      @click="showDeleteConfirmationModal = true"
                      data-testid="delete-button"
                    >
                      {{ $t('delete') }}
                    </b-button>
                    <b-button
                      variant="primary"
                      class="rounded-pill"
                      data-testid="activate-button"
                      @click="showActivateConfirmationModal = true"
                    >
                      {{ $t('activate') }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-collapse>
          </div>
        </b-col>
        <!-- weeks picker -->
        <b-col
          v-if="relapseSchedule.scheduleStartedAt"
          :cols="relapseSchedule.scheduleStartedAt ? 4 : 0"
        >
          <div class="shadow bg-white pb-4">
            <div class="p-3 d-flex justify-content-center align-items-center">
              <statistics-report
                :statistics="statistics"
                :statisticsSection="taskStatusColors"
              />
            </div>
            <div class="select-period">
              <div class="content">
                <WeeksPicker
                  :readOnly="readOnly"
                  :range="range"
                  :dateStart="relapse.scheduleStartedAt"
                  :dateEnd="relapse.scheduleEndAt"
                  :placeholder="$t('select_date')"
                  @sendDate="handleRangeDateClick"
                  @clearDate="clearDate"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- Activate Confirmation -->
      <b-modal
        v-model="showActivateConfirmationModal"
        :static="true"
        ok-title="No, Continue"
        cancel-title="Yes, cancel"
        title="Activate Schedule"
        title-tag="h3"
        footer-class="justify-content-center"
        header-class="justify-content-center"
        id="activation-modal"
        hide-header-close
        no-close-on-backdrop
        centered
      >
        <p class="text-center px-5">
          {{ $t('relapse_schedule_activate_popup') }}
        </p>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="secondary"
            class="rounded-pill"
            @click="cancel()"
            data-testid="activate-cancel-button"
          >
            {{ $t('no_cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="rounded-pill"
            @click="handleScheduleActivation"
            data-testid="modal-activate-button"
          >
            {{ $t('yes_activate_schedule') }}
          </b-button>
        </template>
      </b-modal>

      <!-- Delete Confirmation -->
      <b-modal
        v-model="showDeleteConfirmationModal"
        :static="true"
        ok-title="No, Continue"
        cancel-title="Yes, cancel"
        title="Delete Schedule"
        title-tag="h3"
        header-class="justify-content-center"
        footer-class="justify-content-center"
        id="delete-modal"
        hide-header-close
        no-close-on-backdrop
        centered
      >
        <p class="text-center px-5">
          {{ $t('relapse_schedule_delete_popup') }}
        </p>

        <template #modal-footer="{ cancel }">
          <b-button
            variant="secondary"
            class="rounded-pill"
            @click="cancel()"
            data-testid="delete-cancel-button"
          >
            {{ $t('no_cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="rounded-pill"
            @click="handleScheduleDeletion"
            data-testid="modal-delete-button"
          >
            {{ $t('yes_delete_schedule') }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FollowUpSurvey from '@/components/Relapse/FollowUpSurvey';
import QuestionsSection from '@/components/Relapse/QuestionsSection';
import RelapseSchedule from '@/components/Participants/RelapseSchedule';
import StatisticsReport from '@/components/Participants/StatisticsReport';
import DatePickerMixin from '@/mixins/DatePickerMixin';
import WeeksPicker from '@/components/WeeksPicker';
import { fetchParticipantRelapseSchedule } from '@/api/participant';
import {
  activateRelapseSchedule,
  deleteRelapseSchedule,
  fetchRelapseScheduleStatistics,
  getParticipantRelapse,
} from '@/api/relapses';

export default {
  name: 'view-relapse',
  components: {
    FollowUpSurvey,
    RelapseSchedule,
    StatisticsReport,
    WeeksPicker,
    QuestionsSection,
  },
  mixins: [DatePickerMixin],

  data() {
    return {
      loading: true,
      visions: [
        { id: 0, name: 'Left eye', key: 'hasLossVisionLeftEye' },
        { id: 1, name: 'Right eye', key: 'hasLossVisionRightEye' },
        { id: 2, name: 'Double vision', key: 'hasExperiencedDoubleVision' },
      ],
      relapseSchedule: {},
      readOnly: true,
      submitting: false,
      statistics: {
        completed: null,
        notCompleted: null,
      },
      taskStatusColors: [
        { label: 'notCompleted', color: '#046db3', value: 0 }, // blue
        { label: 'completed', color: '#f58b44', value: 0 }, // orange
      ],
      showActivateConfirmationModal: false,
      showDeleteConfirmationModal: false,
      isTopLevelParticipant: this.$route.name === 'view-relapse',
      statusNone: 'none',
    };
  },

  computed: {
    ...mapState({
      relapse: ({ relapses }) => relapses.selectedRelapse,
      relapsePrams: ({ relapses }) => relapses.relapsePrams,
      participant: (state) => state.participants.selectedParticipant,
      selectedClient: (state) => state.clients.selectedClient,
      study: (state) => state.studies.selectedStudy,
      selectedSite: (state) => state.sites.selectedSite,
      selectedProtocol: (state) => state.protocols.selectedProtocol,
    }),
    ...mapGetters({ dropped: 'participants/isParticipantDroppedOut' }),
    defaultFromDate() {
      return this.getCorrectDateFormat(this.relapse.participant?.dateStart);
    },
  },

  created() {
    this.fetchRelapseDetails();
    this.getParticipantRelapseSchedule();
  },

  watch: {
    relapseSchedule(newValue) {
      // check if the relapse schedule is completed
      if (
        newValue.scheduleStartedAt &&
        this.moment(Date.now()).isAfter(this.moment(newValue.scheduleEndAt))
      ) {
        this.range.start = this.moment(newValue.scheduleStartedAt).format(
          'YYYY-MM-DD'
        );
        this.range.end = this.moment(newValue.scheduleEndAt).format(
          'YYYY-MM-DD'
        );
        this.getStatistics(
          this.getCorrectDateFormat(newValue.scheduleStartedAt),
          this.getCorrectDateFormat(newValue.scheduleEndAt)
        );
        this.readOnly = true;
      } else {
        if (this.dropped) {
          this.readOnly = true;
          this.getStatistics(
            this.getCorrectDateFormat(newValue.scheduleStartedAt),
            this.getCorrectDateFormat(newValue.scheduleEndAt)
          );
        } else {
          this.getStatistics(
            this.getMondayOfCurrentWeek(),
            this.getSundayOfCurrentWeek()
          );
        }
      }
    },
    '$route.params.id': function () {
      this.fetchRelapseDetails();
    },
  },
  methods: {
    getColorClass(propName) {
      const colors = {
        mild: 'text-mild',
        moderate: 'text-moderate',
        severe: 'text-severe',
        none: 'text-none',
      };
      return colors[propName];
    },

    stringToBoolean: function (string) {
      switch (string) {
        case 'true':
        case '1':
          return true;
        case 'false':
        case '0':
          return false;
        default:
          return Boolean(string);
      }
    },

    async getParticipantRelapseSchedule() {
      try {
        const response = await fetchParticipantRelapseSchedule(
          this.relapsePrams
        );
        this.relapseSchedule = response.data;
        const { scheduleStartedAt, scheduleEndAt } = this.relapseSchedule;
        this.getStatistics(
          this.moment(scheduleStartedAt).format('YYYY-MM-DD'),
          this.moment(scheduleEndAt).format('YYYY-MM-DD')
        );
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },

    async getStatistics(dateStart, dateEnd) {
      this.submitting = true;
      this.readOnly = this.dropped ? true : false;
      if (dateStart != 'Invalid date' || dateEnd != 'Invalid date') {
        try {
          const response = await fetchRelapseScheduleStatistics({
            ...this.relapsePrams,
            dateStart,
            dateEnd,
          });
          this.statistics = { ...response.data.tests };
          let notCompleted = response.data.tests.notCompleted;
          let completed = response.data.tests.completed;
          this.taskStatusColors[0].value = notCompleted;
          this.taskStatusColors[1].value = completed;
        } catch (error) {
          this.$toast.error(
            this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
          );
        } finally {
          this.submitting = false;
        }
      }
    },

    async handleScheduleActivation() {
      try {
        await activateRelapseSchedule(this.relapsePrams);

        this.$toast.info(this.$t('toast_relapse_schedule_activated'));
        this.showActivateConfirmationModal = false;
        this.getParticipantRelapseSchedule();
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },

    async handleScheduleDeletion() {
      try {
        await deleteRelapseSchedule(this.relapsePrams);
        this.$toast.info(this.$t('toast_relapse_schedule_deleted'));
        this.showDeleteConfirmationModal = false;
        this.getParticipantRelapseSchedule();
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },

    async fetchRelapseDetails() {
      this.loading = true;
      try {
        const { data } = await getParticipantRelapse(this.relapsePrams);

        this.$store.commit('relapses/SET_SELECTED_RELAPSE', data);
        this.$store.commit('participants/SET_PARTICIPANT_PARAMS', {
          clientId: this.relapsePrams.clientId,
          studyId: this.relapsePrams.studyId,
          participantId: this.relapsePrams.participantId,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.setBreadCrumbData({
          'client-view-relapse': [
            {
              text: this.$t('clients'),
              to: { name: 'clients' },
            },
            {
              text: this.selectedClient.basic?.legalName,
              to: { name: 'client-details' },
            },
            {
              text: this.study?.name,
              to: { name: 'client-study-details' },
            },
            {
              text: this.relapse.participant?.participantDisplayId,
              active: true,
            },
          ],
          'study-view-relapse': [
            {
              text: this.$t('studies'),
              to: { name: 'studies' },
            },
            {
              text: this.study?.name,
              to: { name: 'study-details' },
            },
            {
              text: this.relapse.participant?.participantDisplayId,
              active: true,
            },
          ],
          'protocol-view-relapse': [
            {
              text: this.$t('protocols'),
              to: { name: 'protocols' },
            },

            {
              text: this.selectedProtocol?.name,
              to: { name: 'view-protocol' },
            },

            {
              text: this.relapse.participant?.participantDisplayId,
              active: true,
            },
          ],
          'site-view-relapse': [
            {
              text: this.$t('sites'),
              to: { name: 'sites' },
            },

            {
              text: this.selectedSite.basic?.name,
              to: { name: 'view-site' },
            },
            {
              text: this.relapse.participant?.participantDisplayId,
              active: true,
            },
          ],
          'view-relapse': [
            {
              text: this.$t('participants'),
              to: { name: 'participants' },
            },

            {
              text: this.relapse.participant?.participantDisplayId,
              active: true,
            },
          ],
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text-severe {
  text-transform: capitalize;
  color: #f35656;
}
.text-moderate {
  text-transform: capitalize;
  color: #f59741;
}

.text-mild {
  text-transform: capitalize;
  color: #f5c841;
}

.text-none {
  text-transform: capitalize;
  color: #80c2f9;
}

.calendar-button {
  max-width: 161px;
}
.text-lg {
  font-size: 1.125rem !important;
}
.select-period {
  h3 {
    @include desktop-up {
      border-left: 1px solid #e9ecef;
    }
  }

  .content {
    padding: 10px 20px 20px;
    @include desktop-up {
      border-left: 1px solid #e9ecef;
      height: 276px;
    }
  }
}
</style>
