<template>
  <div>
    <h2>Platform Features</h2>
    <p>
      {{ $t('study_features_info') }}
    </p>
    <hr />

    <b-row>
      <b-col cols="6">
        <label class="mt-4">{{ $t('mobile_platforms') }}</label>
        <div class="pl-lg-4 d-flex">
          <base-checkbox
            class="mb-3 mr-5"
            v-model="studyFeaturesConfig.mobilePlatformIos"
            data-testid="ios-checkbox"
            :disabled="!hasAppropriateProtocols && isEdit"
          >
            {{ $t('os_ios') }}
          </base-checkbox>
          <base-checkbox
            class="mb-3"
            v-model="studyFeaturesConfig.mobilePlatformAndroid"
            data-testid="android-checkbox"
            :disabled="!hasAppropriateProtocols && isEdit"
          >
            {{ $t('os_android') }}
          </base-checkbox>
        </div>

        <label class="mt-4">{{ $t('mobile_app_notifications') }}</label>
        <div class="pl-lg-4">
          <b-form-radio-group
            v-model="studyFeaturesConfig.mobileAppNotifications"
            data-testid="mobile-notifications-inputs"
            :disabled="!hasAppropriateProtocols && isEdit"
          >
            <b-form-radio class="mr-5" :value="true">
              {{ $t('yes') }}</b-form-radio
            >
            <b-form-radio :value="false"> {{ $t('no') }}</b-form-radio>
          </b-form-radio-group>
        </div>
        <!-- Hidden for  future releases. -->
        <!-- <label class="mt-4">Communication</label>
        <div class="pl-lg-4">
          <base-checkbox
            class="mb-3"
            v-model="studyFeaturesConfig.videoCalling"
          >
            Video Calling
          </base-checkbox>
          <base-checkbox class="mb-3" v-model="studyFeaturesConfig.messaging">
            Messaging
          </base-checkbox>
        </div> -->
        <label class="mt-4">{{ $t('relapse_protocol') }}</label>
        <div class="pl-lg-4">
          <b-form-radio-group
            v-model="studyFeaturesConfig.relapseProtocol"
            data-testid="relpase-protocol-inputs"
            :disabled="!hasAppropriateProtocols && isEdit"
          >
            <b-form-radio class="mr-5" :value="true">
              {{ $t('yes') }}
            </b-form-radio>
            <b-form-radio :value="false"> {{ $t('no') }} </b-form-radio>
          </b-form-radio-group>
        </div>
        <label class="mt-4">{{ $t('quick_tour') }}</label>
        <div class="pl-lg-4">
          <b-form-radio-group
            v-model="studyFeaturesConfig.quickTour"
            data-testid="quick-tour-inputs"
            :disabled="!hasAppropriateProtocols && isEdit"
          >
            <b-form-radio class="mr-5" :value="true">
              {{ $t('yes') }}
            </b-form-radio>
            <b-form-radio :value="false"> {{ $t('no') }} </b-form-radio>
          </b-form-radio-group>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="mr-auto">
          <label class="mt-4">{{ $t('custom_participant_id') }}</label>
          <div class="pl-lg-4">
            <b-form-radio-group
              v-model="studyFeaturesConfig.haveCustomParticipantCode"
              data-testid="custom-id-inputs"
              :disabled="!hasAppropriateProtocols && isEdit"
            >
              <b-form-radio
                class="mr-5"
                :value="true"
                data-testid="custom-code-on-value"
              >
                {{ $t('yes') }}
              </b-form-radio>
              <b-form-radio :value="false" data-testid="custom-code-off-value">
                {{ $t('no') }}
              </b-form-radio>
            </b-form-radio-group>
          </div>
          <div
            class="d-flex align-items-center mt-4"
            v-if="studyFeaturesConfig.haveCustomParticipantCode"
          >
            <label>{{ $t('custom_code') }}</label>
            <b-form-input
              v-model="studyFeaturesConfig.customParticipantCode"
              maxLength="8"
              minLength="1"
              class="ml-4 w-25"
              data-testid="custom-id-input"
              :disabled="!hasAppropriateProtocols && isEdit"
            ></b-form-input>
          </div>

          <template>
            <label class="mt-4">{{ $t('languages') }}</label>
            <div>{{ $t('study_lang_info') }}</div>
            <b-row class="pl-lg-4">
              <b-col lg="8" md="12">
                <b-row>
                  <b-col
                    v-for="(language, index) in languagesList"
                    :key="index"
                    lg="6"
                    md="12"
                  >
                    <base-checkbox
                      v-if="languageIsEditable(language.code)"
                      :checked="
                        initialSelectedLanguages.includes(language.code)
                      "
                      @input="
                        (value) => {
                          updateLanguages(value, language.code);
                        }
                      "
                    >
                      <span class="language-name"
                        >{{ language.name }} -
                        {{ language.code.toUpperCase() }}</span
                      >
                    </base-checkbox>
                    <div v-else class="mt-3">
                      <i
                        class="ni ni-check-bold text-primary language-check"
                      ></i>
                      <span class="language-name"
                        >{{ language.name }} -
                        {{ language.code.toUpperCase() }}</span
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="mt-4">{{ $t('patient_diary') }}</label>
                <div class="pl-lg-4">
                  <b-form-radio-group
                    v-model="havePatientDiary"
                    data-testid="study-patient-diary"
                    :disabled="!hasAppropriateProtocols && isEdit"
                  >
                    <b-form-radio class="mr-5" :value="true">
                      {{ $t('yes') }}
                    </b-form-radio>
                    <b-form-radio :value="false"> {{ $t('no') }} </b-form-radio>
                  </b-form-radio-group>
                </div>
              </b-col>
            </b-row>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="handleNextClick"
          :disabled="
            !validForm ||
            !languageSelected ||
            (havePatientDiary !== true && havePatientDiary !== false)
          "
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { languagesDefault } from '@/constants/languages-constants';
import { isNil } from 'lodash/lang';
import { getFeaturesConfig } from '@/api/resources';
export default {
  components: {},
  data() {
    return {
      languagesList: [...languagesDefault],
      initialSelectedLanguages: [],
      languageSelected: false,
      isEdit: this.$route.name.includes('edit'),
      havePatientDiary: null,
      studyFeaturesConfig: {
        mobilePlatformIos: false,
        mobilePlatformAndroid: false,
        quickTour: null,
        relapseProtocol: null,
        haveCustomParticipantCode: null,
        customParticipantCode: '',
        videoCalling: false,
        messaging: false,
        mobileAppNotifications: null,
        language: 'English',
        languages: [],
        widgets: {},
      },
    };
  },
  computed: {
    ...mapState({
      selectedStudy: ({ studies }) => studies.selectedStudy,
    }),
    ...mapGetters({
      hasAppropriateProtocols: 'studies/hasAppropriateProtocols',
    }),
    validForm() {
      const {
        mobilePlatformIos,
        mobilePlatformAndroid,
        haveCustomParticipantCode,
        customParticipantCode,
      } = this.studyFeaturesConfig;
      return haveCustomParticipantCode
        ? (mobilePlatformIos || mobilePlatformAndroid) && customParticipantCode
        : mobilePlatformIos || mobilePlatformAndroid;
      // &&(videoCalling || messaging)
    },
  },
  created() {
    if (this.isEdit) {
      this.checkStudyFeaturesConfigOriginalState();
    }
    this.initFeaturesAndSelectedLanguages();
    this.getWidgetResources();
  },
  watch: {
    havePatientDiary(newValue) {
      if (newValue) {
        this.getWidgetResources();
      } else {
        this.studyFeaturesConfig.widgets = {};
      }
    },
  },
  methods: {
    initFeaturesAndSelectedLanguages() {
      const { languages: selectedLanguages, ...rest } = this.isEdit
        ? this.selectedStudy?.studyFeaturesConfig ?? {}
        : this.studyFeaturesConfig;

      this.studyFeaturesConfig = {
        ...rest,
        languages: [...selectedLanguages],
      };

      this.languageSelected = selectedLanguages.length > 0;
      this.initialSelectedLanguages = [...selectedLanguages];
    },
    handleNextClick() {
      this.$emit('nextStep', this.studyFeaturesConfig);
    },
    updateLanguages(value, languageCode) {
      const { languages } = this.studyFeaturesConfig;
      const index = languages.indexOf(languageCode);
      if (value && index < 0) {
        languages.push(languageCode);
      } else if (!value && index >= 0 && index < languages.length) {
        languages.splice(index, 1);
      }
      this.languageSelected = languages.length > 0;
    },
    languageIsEditable(languageCode) {
      const { protocolsStatus } = this.selectedStudy;
      const studyHasProtocols =
        !isNil(protocolsStatus) && Object.values(protocolsStatus).length > 0;
      const languageIsSelected =
        this.initialSelectedLanguages.includes(languageCode);
      return !studyHasProtocols || !languageIsSelected;
    },

    async getWidgetResources() {
      const response = await getFeaturesConfig();
      const widgets = [];

      if (Array.isArray(response.data) && response.data.length > 0) {
        const { id, name } = response.data[0];
        if (name === 'patientDiary') {
          widgets.push({ id, replicationMode: 'DAILY' });
        }
      }
      this.studyFeaturesConfig.widgets = widgets;
    },

    checkStudyFeaturesConfigOriginalState() {
      if (
        this.$store.state.studies.selectedStudy.studyFeaturesConfig
          .studyFeaturesConfigWidgets[0]?.widget?.name === 'patientDiary'
      ) {
        this.havePatientDiary = true;
      } else {
        this.havePatientDiary = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.language-check {
  margin-left: 0.5rem;
  margin-right: 1.5rem;
}
.language-name {
  font-size: 1rem;
  font-family: Avenir-Bold, Helvetica, Arial !important;
}
</style>
