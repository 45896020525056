<template>
  <div>
    <h2 class="">{{ $t('study_tasks') }}</h2>
    <p>{{ $t('study_tasks_info') }}</p>
    <hr />

    <div>
      <b-row>
        <b-col>
          <div v-if="studyTestsList">
            <study-tasks-selection-component
              :studyTasksList="removeEmptyTests(studyTestsList)"
              @nextStep="nextStep"
            ></study-tasks-selection-component>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StudyTasksSelectionComponent from '@/components/Study/StudyTasksSelectionComponent.vue';
import { getTasksList } from '@/api/resources';

export default {
  components: { StudyTasksSelectionComponent },
  data() {
    return {
      studyTestsList: [],
      isEdit: this.$route.name.includes('edit'),
    };
  },
  created() {
    this.makeRequest();
  },

  computed: {
    ...mapState({
      participantTasks: (state) => state.resources.tests,
      excludedTask: (state) => state.participants.excluded_games,
    }),
    ...mapGetters({
      tasksFromAlreadyCreatedStudy: 'studies/tasksFromAlreadyCreatedStudy',
    }),
  },

  methods: {
    async makeRequest() {
      try {
        const { data } = await getTasksList();
        this.studyTestsList = data;
      } catch (error) {
        this.$toast.error(this.$t('error_something_went_wrong'));
        console.error(error);
      }
    },
    mapCategoriesAndTests(category, obj, selectedTests) {
      if (selectedTests && Array.isArray(selectedTests)) {
        const allTestsAlreadyInStudy = category.tests.every((test) =>
          selectedTests.some(
            (selectedTest) =>
              selectedTest.partnerName === obj.name &&
              selectedTest.tests.ids.includes(test.id)
          )
        );
        return {
          ...category,
          partnerName: obj.name,
          selectedCategory: false,
          tests: category.tests.map((test) => {
            let isSelected = false;
            if (selectedTests) {
              for (const selectedTest of selectedTests) {
                if (
                  selectedTest.partnerName === obj.name &&
                  selectedTest.tests.ids.includes(test.id)
                ) {
                  isSelected = true;
                  break;
                }
              }
            }
            return {
              ...test,
              selectedTest: isSelected,
              alreadyInStudy: isSelected,
            };
          }),
          allTestsAlreadyInStudy,
        };
      } else {
        return {
          ...category,
          partnerName: obj.name,
          selectedCategory: false,
          tests: category.tests.map((test) => ({
            ...test,
            selectedTest: false,
            alreadyInStudy: false,
          })),
          allTestsAlreadyInStudy: false,
        };
      }
    },

    removeEmptyTests(objectsArray) {
      return objectsArray.map((obj) => {
        const newObj = { ...obj, selectedPartner: false };
        newObj.categories = obj.categories
          .filter((category) => category.tests.length > 0)
          .map((category) => {
            if (this.tasksFromAlreadyCreatedStudy && this.isEdit) {
              return this.mapCategoriesAndTests(
                category,
                obj,
                this.tasksFromAlreadyCreatedStudy
              );
            } else {
              return this.mapCategoriesAndTests(category, obj, null);
            }
          });
        return newObj;
      });
    },

    nextStep() {
      this.$emit('nextStep');
    },
  },
};
</script>

<style>
.task-text {
  font-size: 15px;
}
.task-text-languages {
  font-size: 11px;
}
.task-container {
  display: flex;
  flex-direction: column;
  margin-top: -7px;
}
</style>
