<template>
  <v-client-table
    :data="clientsListTableData"
    :columns="columns"
    :options="options"
    class="tableClass"
    ref="clientsTable"
    @sorted="handleSorted"
  >
    <template slot="legalName" slot-scope="props">
      <span>{{ props.row.legalName }}</span
      ><span
        v-if="showHighlightTag && props.row.id === $route.params.clientId"
        class="highlight-tag"
        >{{ highlightTagText }}</span
      >
    </template>
    <template slot="createdAt" slot-scope="props">
      <span v-if="props.row.createdAt">
        {{ moment.unix(props.row.createdAt).format('DD/MM/YYYY') }}
      </span>
    </template>
    <template slot="lastUpdatedAt" slot-scope="props">
      <span v-if="props.row.lastUpdatedAt">
        {{ moment.unix(props.row.lastUpdatedAt).format('DD/MM/YYYY') }}
      </span>
    </template>
    <template slot="actions" slot-scope="props">
      <div class="cursor-pointer text-right" @click="goToDetail(props.row)">
        <span class="view-details">{{ $t('view') }}</span>
        <i class="fas fa-chevron-right ml-2"></i>
      </div>
    </template>
  </v-client-table>
</template>

<script>
import listHighLightMixin from '@/mixins/listHighLightMixin';

export default {
  props: {
    clientsListTableData: Array,
    orderBy: {
      type: Object,
      default: () => ({
        column: 'createdAt',
        ascending: false,
      }),
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },

  mixins: [listHighLightMixin],
  data() {
    return {
      isDashboardView: this.$route.name === 'dashboard',
      columns: ['legalName', 'createdAt', 'lastUpdatedAt', 'actions'],
      options: {
        headings: {
          legalName: this.$t('name'),
          createdAt: this.$t('date_creation'),
          lastUpdatedAt: this.$t('date_last_update'),
          actions: '',
        },
        sortable: ['createdAt', 'legalName', 'lastUpdatedAt'],
        orderBy: this.orderBy,
        perPage: 9999,
      },
    };
  },
  methods: {
    goToDetail(row) {
      if (!this.isLoading) this.$emit('goToDetail', row);
    },
    handleSorted({ column, ascending }) {
      this.$refs.clientsTable.setOrder(column, ascending);
      this.$emit('sorting', column, ascending);
    },
  },
};
</script>
