<template>
  <div class="pt-5 px-5">
    <wizard-header
      title="Create new Client"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard :currentStep="step" wizardType="clients"></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <basic-information
        :basic="client.basic"
        v-show="step === 0"
        @nextStep="step++"
      />
      <client-representatives
        v-show="step === 1"
        @nextStep="handleRepNext"
        @back="step--"
      />
      <contact-address
        v-show="step === 2"
        @nextStep="handleContactClick"
        @back="step--"
      />
      <invoice-address
        v-show="step === 3"
        @nextStep="handleInvoiceClick"
        @back="step--"
      />
      <overview :client="client" v-if="step === 4" @back="step--" />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      ok-title="No, Continue"
      cancel-title="Yes, cancel"
      title="Create new Client"
      title-tag="h3"
      header-class="justify-content-center"
      footer-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center">{{ $t('exit_create_client') }}</p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="$router.push({ name: 'clients' })"
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BasicInformation from '@/components/Clients/BasicInformation';
import ClientRepresentatives from '@/components/Clients/ClientRepresentatives';
import ContactAddress from '@/components/Clients/ContactAddress';
import InvoiceAddress from '@/components/Clients/InvoiceAddress';
import Overview from '@/components/Clients/Overview';
import { mapState } from 'vuex';
import WizardHeader from '../../components/WizardHeader.vue';
import StepWizard from '../../components/StepWizard.vue';

export default {
  components: {
    BasicInformation,
    ClientRepresentatives,
    ContactAddress,
    InvoiceAddress,
    Overview,
    WizardHeader,
    StepWizard,
  },
  data() {
    return {
      addingClient: false,
      step: 0,
      newRep: {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        phone: '',
      },
      showAddRep: false,
      client: {
        invoice: {
          address: '',
          city: '',
          countryId: '',
          postalCode: '',
        },
        basic: {
          programManagerId: '',
          clientTypeId: '',
          legalName: '',
          description: '',
        },
        representatives: [],
        contact: {
          website: '',
          email: '',
          phone: '',
          address: '',
          city: '',
          countryId: '',
          postalCode: '',
        },
      },
      showConfirmationModal: false,
    };
  },
  created() {
    this.setBreadCrumbData({
      'create-client': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.$t('client_create_new'),
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapState('experts', ['managers']),
    ...mapState('resources', ['clientTypes', 'countries']),
    validEmail() {
      return (
        !!this.newRep.email &&
        /^[a-zA-Z+0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
          this.newRep.email
        )
      );
    },
    emailStatus() {
      return this.newRep.email === '' ? null : this.validEmail;
    },
  },
  methods: {
    handleRepNext(payload) {
      this.client.representatives = [...payload];
      this.step++;
    },
    handleContactClick(payload) {
      this.client.contact = { ...payload };
      this.step++;
    },
    handleInvoiceClick(payload) {
      this.client.invoice = { ...payload };
      this.step++;
    },
  },
};
</script>

<style></style>
