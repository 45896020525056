<template>
  <div class="mb-3">
    <p class="avenir-bold p-3 bg-white mb-0">{{ $t('clients') }}</p>
    <p v-if="firstLoading" class="p-3 bg-white">{{ $t('loading') }}</p>
    <clients-table
      v-else
      :clientsListTableData="clients"
      :order-by="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import ClientsTable from '@/components/Tables/ClientsTable.vue';
import { paginationDefaults } from '@/constants/pagination-constants';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DashboardClientList',
  components: { ClientsTable },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      clients: ({ clients }) => clients.clients,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('clients');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
      pagination: {
        pageSize: paginationDefaults.dashboardPageSize,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
    };
  },
  created() {
    this.getListData('clients');
  },
  methods: {
    ...mapActions({
      fetchListData: 'clients/fetchClients',
    }),
    goToDetail(row) {
      const params = {
        id: row.id,
        name: row.legalName,
      };
      this.$emit('goToDetail', params);
    },
  },
};
</script>
