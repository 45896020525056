<template>
  <div class="mb-3">
    <p class="avenir-bold p-3 bg-white mb-0">{{ $t('sites') }}</p>
    <p v-if="firstLoading" class="p-3 bg-white">{{ $t('loading') }}</p>
    <sites-table
      v-else
      :sitesListTableData="sites"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import listMixin from '@/mixins/listMixin';
import { paginationDefaults } from '@/constants/pagination-constants';
import SitesTable from '@/components/Tables/SitesTable.vue';

export default {
  name: 'DashboardSiteList',
  components: { SitesTable },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      study: ({ studies }) => studies.selectedStudy,
      protocol: ({ protocols }) => protocols.selectedProtocol,
      sites: ({ sites }) => sites.list,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('sites');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
      pagination: {
        pageSize: paginationDefaults.dashboardPageSize,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
    };
  },
  created() {
    this.getListData('sites');
  },
  methods: {
    ...mapActions({
      fetchListData: 'sites/fetchSites',
      setSiteParams: 'sites/setSiteParams',
      setIsProtocolView: 'sites/setIsProtocolView',
    }),
    goToDetail(site) {
      this.setSiteParams({
        clientId: site.client.id,
        studyId: site.study.id,
        protocolId: site.protocol.id,
        siteId: site.id,
      });
      this.setIsProtocolView(this.isProtocolView());
      this.$emit('goToDetail');
    },
    isProtocolView() {
      return this.$route.name.includes('protocol-sites');
    },
  },
};
</script>
