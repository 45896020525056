<template>
  <b-spinner class="mx-auto d-block" v-if="firstLoading" />
  <div v-else>
    <div class="clearfix">
      <router-link to="/clients/create">
        <base-button
          type="primary"
          class="float-right mb-4"
          rounded
          v-if="canCreateClient"
          >{{ $t('client_new') }}</base-button
        >
      </router-link>
    </div>
    <clients-table
      :clientsListTableData="clients"
      :order-by="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
    <PaginatorTableComponent
      @changePageEvent="handleChangePageEvent"
      :current-page="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :page-size="pagination.pageSize"
    />
  </div>
</template>

<script>
import PaginatorTableComponent from '@/shared/components/PaginatorTableComponent.vue';
import ClientsTable from '@/components/Tables/ClientsTable.vue';
import listMixin from '@/mixins/listMixin';
import { mapActions, mapState } from 'vuex';
import { paginationDefaults } from '@/constants/pagination-constants';

export default {
  name: 'ClientList',
  components: {
    PaginatorTableComponent,
    ClientsTable,
  },
  mixins: [listMixin],
  computed: {
    ...mapState({
      clients: ({ clients }) => clients.clients,
      storePagination: ({ clients }) => clients.pagination,
    }),
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      pagination: {
        pageSize: paginationDefaults.itemsPerPage,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
    };
  },
  created() {
    this.getListData('clients');
  },
  methods: {
    ...mapActions({
      fetchListData: 'clients/fetchClients',
    }),
    goToDetail(row) {
      const params = {
        id: row.id,
        name: row.legalName,
      };
      this.$emit('goToDetail', params);
    },
  },
};
</script>
