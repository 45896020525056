<template>
  <div>
    <h2 class="">{{ $t('client_information') }}</h2>
    <b-row>
      <b-col cols="6">
        <label>{{ $t('program_manager') }}</label>
        <v-select
          v-model="basic.programManagerId"
          :placeholder="$t('select_healios_user')"
          :options="managers"
          :reduce="(manager) => manager.id"
          :disabled="managers.length === 0"
          id="program-manager"
        />
      </b-col>
      <b-col cols="6">
        <label>{{ $t('client_type') }}</label>
        <v-select
          class=""
          v-model="basic.clientTypeId"
          label="name"
          placeholder="Select Type"
          :options="clientTags"
          :reduce="(client) => client.id"
          :disabled="clientTypes.length === 0"
          id="client-type"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label>{{ $t('client_name_legal') }}</label>
        <b-form-input
          v-model="basic.legalName"
          :placeholder="$t('client_name_unique')"
          maxlength="100"
          data-testid="legalName-input"
        ></b-form-input>
      </b-col>
      <b-col cols="12">
        <label>{{ $t('description') }}</label>
        <textarea
          rows="4"
          maxlength="400"
          v-model="basic.description"
          class="form-control form-control"
          :placeholder="$t('description')"
          data-testid="description-input"
        />
      </b-col>
    </b-row>

    <div class="mt-5">
      <base-button
        type="primary"
        :disabled="!validForm || validating"
        @click="shouldValidate ? handleNextClick() : $emit('nextStep')"
        rounded
        data-testid="next-button"
      >
        {{ $t('next') }}
        <b-spinner v-if="validating" small></b-spinner>
        <arrow-right v-else />
      </base-button>
    </div>
  </div>
</template>

<script>
import { fetchClients } from '@/api/client';
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      validating: false,
      getClientType2: [],
      clientTags: [],
    };
  },
  props: {
    basic: Object,
  },
  computed: {
    ...mapState({
      managers: (state) => state.experts.managers,
      clientTypes: (state) => state.resources.clientTypes,
      client: (state) => state.clients.selectedClient,
    }),
    validForm() {
      const { legalName, programManagerId, clientTypeId } = this.basic;
      return !!legalName && !!programManagerId && !!clientTypeId;
    },
    shouldValidate() {
      return !(
        this.$route.name === 'edit-client' &&
        this.client.basic.legalName === this.basic.legalName
      );
    },
  },
  created() {
    this.$store.dispatch('experts/dispatchFetchManagers');
    this.extractClientKey();
  },
  methods: {
    extractClientKey() {
      const clientTypesData = this.clientTypes;
      let getNames = clientTypesData.reduce((newArr, clientType) => {
        newArr.push({ name: this.$t(`${clientType.name}`), id: clientType.id });
        return newArr;
      }, []);
      this.clientTags = getNames;
    },
    async handleNextClick() {
      this.validating = true;
      try {
        const { data } = await fetchClients({
          'filter[legal_name]': this.basic.legalName,
        });
        data.length === 0
          ? this.$emit('nextStep')
          : this.$toast.error(this.$t('error_client_legal_name_in_use'));
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.validating = false;
      }
    },
  },
};
</script>

<style></style>
