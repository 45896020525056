var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white shadow p-3"},[_c('b-row',{staticClass:"border-bottom mx-0"},[_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('p',{staticClass:"avenir-bold mb-0 text-lg"},[_vm._v(" "+_vm._s(_vm.surveyType === 'one' ? this.$t('relapse_followup_first_survey') : this.$t('relapse_followup_second_survey'))+" ")])]),_c('b-col',{staticClass:"d-flex flex-md-column justify-content-sm-around align-items-center",attrs:{"md":"12","lg":"6"}},[(_vm.completedAtDate)?_c('app-badge',{staticClass:"mb-2",attrs:{"medium":"","variant":"grey"}},[_vm._v(" "+_vm._s(_vm.getDatafromTimestamp(_vm.completedAtDate))+" ")]):_vm._e(),(
          _vm.moment(_vm.scheduledDate).isSameOrAfter(_vm.moment(Date.now())) &&
          !_vm.completedAtDate &&
          !_vm.dropped
        )?_c('app-badge',{staticClass:"mb-2",attrs:{"medium":"","variant":_vm.isParticipantDroppedOut ? 'red' : 'orange'}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.isParticipantDroppedOut ? _vm.$t('overdue') : _vm.$t('planned')))]),_vm._v(_vm._s(_vm.moment(_vm.scheduledDate).format('DD/MM/YYYY'))+" ")]):_vm._e(),(
          _vm.moment(Date.now()).isBefore(_vm.moment(_vm.scheduledDate).add(7, 'days')) &&
          _vm.moment(Date.now()).isAfter(_vm.scheduledDate) &&
          !_vm.completedAtDate
        )?_c('app-badge',{staticClass:"mb-2",attrs:{"medium":"","variant":"green"}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('due'))+" ")]),_vm._v(_vm._s(_vm.moment(_vm.scheduledDate).add(7, 'days').format('DD/MM/YYYY'))+" ")]):_vm._e(),(
          _vm.moment(Date.now()).isAfter(_vm.moment(_vm.scheduledDate).add(7, 'days')) &&
          !_vm.completedAtDate
        )?_c('app-badge',{staticClass:"mb-2",attrs:{"medium":"","variant":"red"}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('overdue'))+" ")]),_vm._v(_vm._s(_vm.moment(_vm.scheduledDate).add(7, 'days').format('DD/MM/YYYY'))+" ")]):_vm._e(),(
          _vm.moment(_vm.scheduledDate).isSameOrAfter(_vm.moment(Date.now())) &&
          !_vm.completedAtDate &&
          _vm.dropped
        )?_c('app-badge',{staticClass:"mb-2",attrs:{"medium":"","variant":"red"}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('overdue'))+" ")]),_vm._v(_vm._s(_vm.moment(_vm.scheduledDate).add(7, 'days').format('DD/MM/YYYY'))+" ")]):_vm._e()],1)],1),_c('b-row',{staticClass:"mx-0 mt-4"},[_c('b-col',{staticClass:"px-2 mb-4",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-grey"},[_vm._v(_vm._s(_vm.$t('have_you_recovered')))]),(_vm.completedAtDate)?_c('p',{staticClass:"mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.recoveryStatus.name))+" ")]):_vm._e()]),_c('b-col',{staticClass:"px-2 mb-4",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-grey"},[_vm._v(_vm._s(_vm.$t('how_long_did_recovery_take')))]),(_vm.completedAtDate)?_c('p',{staticClass:"mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.recoveryDuration.name))+" ")]):_vm._e()]),_c('b-col',{staticClass:"px-2 mb-4",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-grey"},[_vm._v(" "+_vm._s(_vm.$t('did_you_get_corticoids_treatment'))+" ")]),(_vm.completedAtDate)?_c('p',{staticClass:"mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm.gotCorticoids ? this.$t('yes') : this.$t('no'))+" ")]):_vm._e()]),_c('b-col',{staticClass:"px-2 mb-4",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-grey"},[_vm._v(" "+_vm._s(_vm.$t('did_you_experience_impact_apply_all'))+" ")]),(_vm.experienceImpacts.length > 0 && _vm.completedAtDate)?_c('div',_vm._l((_vm.experienceImpacts),function(impact,i){return _c('p',{key:i,staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t(impact.experienceImpact.name))+" ")])}),0):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }