<template>
  <div class="p-5">
    <ClientList @goToDetail="goToDetail" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ClientList from '@/components/Clients/List/ClientList.vue';

export default {
  name: 'ClientListContainerView',
  components: { ClientList },
  created() {
    this.getResources();
    this.setBreadCrumbData({
      clients: [
        {
          text: this.$t('clients'),
          active: true,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      fetchResources: 'resources/dispatchFetchResources',
    }),
    async getResources() {
      try {
        await this.fetchResources();
      } catch (error) {
        console.error(error);
      }
    },
    goToDetail(params) {
      this.$router.push({
        name: 'client-details',
        params,
      });
    },
  },
};
</script>
