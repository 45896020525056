<template>
  <div>
    <h2>{{ $t('client_address_official_contact') }}</h2>

    <b-row>
      <b-col cols="6">
        <label>{{ $t('website') }}</label>
        <b-form-input
          v-model="contact.website"
          :state="websiteStatus"
          :placeholder="$t('website_tip')"
          data-testid="website-input"
        ></b-form-input>
        <b-form-invalid-feedback id="url-live-feedback">{{
          $t('error_url_not_valid')
        }}</b-form-invalid-feedback>
      </b-col>
      <b-col cols="6">
        <label>{{ $t('email') }}</label>
        <b-form-input
          v-model="contact.email"
          :state="emailStatus"
          :placeholder="$t('email_tip')"
          maxLength="150"
          data-testid="email-input"
          aria-describedby="email-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="email-live-feedback">{{
          $t('error_email_not_valid')
        }}</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label>{{ $t('phone') }}</label>
        <phone-number-input-by-country v-model="contact.phone" />
      </b-col>
      <b-col cols="12">
        <label>{{ $t('address') }}</label>
        <b-form-input
          v-model="contact.address"
          :placeholder="$t('address')"
          data-testid="address-input"
          maxLength="400"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <label>{{ $t('city') }}</label>
            <b-form-input
              v-model="contact.city"
              :placeholder="$t('city')"
              maxLength="150"
              data-testid="city-input"
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('country') }}</label>
            <v-select
              v-model="contact.countryId"
              :placeholder="$t('country')"
              :options="countries"
              :reduce="(country) => country.id"
              label="shortName"
              id="countries"
            />
          </b-col>
          <b-col cols="4">
            <label>{{ $t('postal_code') }}</label>
            <b-form-input
              v-model="contact.postalCode"
              :placeholder="$t('postal_code')"
              maxLength="10"
              data-testid="postal-input"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          :disabled="!validForm || !emailStatus"
          @click="handleNextClick"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PhoneNumberInputByCountry from '@/components/PhoneNumberInput';
import { validEmail, validateUrl } from '@/utils/validations';
import { mapState } from 'vuex';

export default {
  components: {
    PhoneNumberInputByCountry,
  },
  data() {
    return {
      contact: {
        type: 'contact',
        website: '',
        email: null,
        phone: '',
        address: '',
        city: '',
        countryId: '',
        postalCode: '',
      },
      isEdit: this.$route.name === 'edit-client',
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.resources.countries,
      client: ({ clients }) => clients.selectedClient,
    }),
    emailStatus() {
      return this.contact.email === null
        ? null
        : validEmail(this.contact.email);
    },
    websiteStatus() {
      return this.contact.website === ''
        ? null
        : validateUrl(this.contact.website);
    },

    checkInputsLength() {
      const { email, address, city, postalCode } = this.contact;
      return (
        address.length < 401 &&
        city.length < 151 &&
        postalCode.length < 11 &&
        email.length < 151
      );
    },
    validForm() {
      const { website, email, phone, address, city, countryId, postalCode } =
        this.contact;
      return (
        !!website &&
        !!email &&
        !!phone &&
        !!address &&
        !!city &&
        !!countryId &&
        !!postalCode &&
        this.checkInputsLength
      );
    },
  },
  created() {
    if (this.isEdit) {
      const { website, email, phone, address, city, countryId, postalCode } =
        this.client.contact;
      this.contact = {
        type: 'contact',
        website,
        email,
        phone,
        address,
        city,
        countryId,
        postalCode,
      };
    }
  },
  methods: {
    handleNextClick() {
      if (validateUrl(this.contact.website)) {
        this.$emit('nextStep', this.contact);
      } else {
        this.$toast.error(this.$t('error_url_not_valid'));
      }
    },
  },
};
</script>

<style></style>
