<template>
  <div class="m-5">
    <b-row>
      <b-col>
        <div class="display-3">{{ $t('welcome') }} {{ userName }}!</div>
        <div :class="[isClientUser ? 'mb-1' : 'mb-4']">
          {{ $t('dashboard_check_activities') }}
        </div>
        <ListFiltersComponent
          v-if="isClientUser"
          :filtersToDisplay="filtersToDisplay"
          class="mb-3"
          @changeFilterEvent="handleChangeFilterEvent"
        />
      </b-col>
    </b-row>

    <b-row v-if="!navigatorPermissions">
      <b-col :cols="isClientUser ? '8' : '12'">
        <DashboardClientList
          v-if="isHealiosUser"
          @goToDetail="goToSelectedClient"
        />
        <DashboardStudyList @goToDetail="goToSelectedStudy" />
        <DashboardParticipantList
          v-if="isClientUser"
          :filterData="requireAttentionFilterData"
          @goToDetail="goToSelectedParticipant"
        />
        <DashboardProtocolList @goToDetail="goToSelectedProtocol" />
        <DashboardSiteList @goToDetail="goToSelectedSite" />
      </b-col>
      <b-col cols="4" v-if="isClientUser">
        <div class="shadow bg-white">
          <div class="bg-white mb-4 border">
            <b-row class="border-bottom mx-0 py-3 px-1">
              <b-col>
                <p class="avenir-bold mb-0">
                  {{ $t('adherence_to_schedules') }}
                </p>
              </b-col>
            </b-row>
            <b-row class="mx-0 flex-column align-items-center py-3">
              <b-col>
                <vc-donut
                  background="white"
                  foreground=" rgba(	52,	144,	205, 0.2)"
                  unit="px"
                  :thickness="35"
                  legend-placement="right"
                  :sections="schedulesSection"
                  :total="schedulesStatistics.total"
                  :start-angle="0"
                  :auto-adjust-text-size="true"
                  class="report-chart"
                >
                  <h1 class="chart-text">
                    <span class="d-block"
                      >{{ schedulesStatistics.completed }} %
                    </span>
                  </h1>
                </vc-donut>
              </b-col>
              <b-col class="mt-4 pr-0 pl-5">
                <div class="report-numbers d-flex align-items-center mb-3">
                  <div class="circle circle-orange"></div>
                  <div class="ml-3">
                    <p class="mb-0 avenir-bold text-xl">
                      {{ schedulesStatistics.completed }} %
                    </p>
                    <p class="mb-0 opacity-6 text-sm">{{ $t('completed') }}</p>
                  </div>
                </div>
                <div class="report-numbers d-flex align-items-center">
                  <div class="circle circle-blue-light"></div>
                  <div class="ml-3">
                    <p class="mb-0 avenir-bold text-xl">
                      {{ schedulesStatistics.notCompleted }} %
                    </p>
                    <p class="mb-0 opacity-6 text-sm">
                      {{ $t('not_completed') }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div class="shadow bg-white">
          <div class="bg-white mb-4 border">
            <b-row class="border-bottom mx-0 py-3 px-1">
              <b-col>
                <p class="avenir-bold mb-0">{{ $t('participants') }}</p>
              </b-col>
            </b-row>
            <b-row class="mx-0 flex-column align-items-center py-3">
              <b-col>
                <vc-donut
                  background="white"
                  foreground=" rgba(	52,	144,	205, 0.2)"
                  unit="px"
                  :thickness="35"
                  legend-placement="right"
                  :sections="participantsSection"
                  :total="participantsStatistics.total"
                  :start-angle="0"
                  :auto-adjust-text-size="true"
                  class="report-chart"
                >
                  <h1 class="chart-text">
                    <span class="d-block"
                      >{{ participantsStatistics.total }}
                    </span>
                    <span class="chart-text-md font-weight-normal">
                      {{ $t('total') }}</span
                    >
                  </h1>
                </vc-donut>
              </b-col>
              <b-col class="mt-4 pr-0 pl-5">
                <div class="report-numbers d-flex align-items-center mb-3">
                  <div class="circle circle-orange"></div>
                  <div class="ml-3">
                    <p class="mb-0 avenir-bold text-xl">
                      {{ participantsStatistics.active }}
                    </p>
                    <p class="mb-0 opacity-6 text-sm">
                      {{ $t('participants_active') }} {{ $t('total') }}
                    </p>
                  </div>
                </div>
                <div class="report-numbers d-flex align-items-center mb-3">
                  <div class="circle circle-blue"></div>
                  <div class="ml-3">
                    <p class="mb-0 avenir-bold text-xl">
                      {{ participantsStatistics.completed }}
                    </p>
                    <p class="mb-0 opacity-6 text-sm">
                      {{ $t('participants_completed') }}
                    </p>
                  </div>
                </div>
                <div class="report-numbers d-flex align-items-center">
                  <div class="circle circle-blue-light"></div>
                  <div class="ml-3">
                    <p class="mb-0 avenir-bold text-xl">
                      {{ participantsStatistics.pending }}
                    </p>
                    <p class="mb-0 opacity-6 text-sm">
                      {{ $t('participants_pending') }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { fetchClientStatistics } from '@/api/client';

// Tables
import { calcPercentage } from '@/utils';

import DashboardSiteList from '@/components/Sites/List/DashboardSiteList.vue';
import DashboardProtocolList from '@/components/Protocol/List/DashboardProtocolList.vue';
import DashboardParticipantList from '@/components/Participants/List/DashboardParticipantList.vue';
import DashboardStudyList from '@/components/Study/List/DashboardStudyList.vue';
import ListFiltersComponent from '@/shared/components/ListFiltersComponent.vue';
import DashboardClientList from '@/components/Clients/List/DashboardClientList.vue';

export default {
  components: {
    DashboardClientList,
    ListFiltersComponent,
    DashboardStudyList,
    DashboardParticipantList,
    DashboardProtocolList,
    DashboardSiteList,
  },
  data() {
    return {
      clients: [],
      filtersToDisplay: {
        client: false,
        study: true,
        protocol: false,
        site: false,
      },
      schedulesStatistics: {
        total: null,
        completed: null,
        notCompleted: null,
      },
      requireAttentionFilterData: {
        subStatus: 'relapse',
      },
      participantsStatistics: {
        total: null,
        active: null,
        completed: null,
        pending: null,
      },
      schedulesSection: [],
      participantsSection: [],
    };
  },
  computed: {
    ...mapState({
      userName: ({ auth }) => `${auth.firstName} ${auth.lastName}`,
      userType: ({ auth }) => auth.userType,
    }),
    ...mapGetters({
      isClientUser: 'auth/isClientUser',
      isHealiosUser: 'auth/isHealiosUser',
    }),
  },
  created() {
    this.setBreadCrumbData({
      dashboard: [
        {
          text: this.$t('home'),
          active: true,
        },
      ],
    });

    if (this.isClientUser) this.getClientStatistics();
  },
  methods: {
    ...mapActions({
      setProtocolParams: 'protocols/setProtocolParams',
      setSiteParams: 'sites/setSiteParams',
    }),
    goToSelectedClient(params) {
      this.$router.push({
        name: 'client-details',
        params,
      });
    },
    goToSelectedStudy() {
      this.$router.push({ name: 'study-details' });
    },
    goToSelectedProtocol(protocolId) {
      this.$router.push({ name: 'view-protocol', params: { id: protocolId } });
    },
    goToSelectedSite() {
      this.$router.push({
        name: 'view-site',
      });
    },
    goToSelectedParticipant(participantDisplayId) {
      this.$router.push({
        name: 'view-participant',
        params: { id: participantDisplayId },
      });
    },
    async getClientStatistics(studyId = null) {
      try {
        const {
          data: { participants, schedules },
        } = await fetchClientStatistics({
          clientId: this.$store.state.auth.clientId,
          studyId: studyId,
        });

        this.schedulesStatistics = {
          total: schedules.total,
          completed: schedules.completed
            ? calcPercentage(schedules.completed, schedules.total)
            : schedules.completed,
          notCompleted: schedules.notCompleted
            ? calcPercentage(schedules.notCompleted, schedules.total)
            : schedules.notCompleted,
        };

        this.schedulesSection = [
          { value: schedules.completed, color: '#f58b44' },
        ];

        this.participantsStatistics = { ...participants };
        this.participantsSection = [
          { value: participants.active, color: '#f58b44' },
          {
            value: participants.completed,
            color: '#046DB3',
          },
        ];
      } catch (error) {
        this.displayError('Error get client statistics', error);
      }
    },
    handleChangeFilterEvent(filter) {
      this.getClientStatistics(filter.studyId);
    },
  },
};
</script>
<style></style>
