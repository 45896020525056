<template>
  <div>
    <div class="card shadow mb-5">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="mb-0">
              {{ $t('client_representatives') }}
            </h4>
          </div>
          <div class="col text-right">
            <!-- Add Representative Modal -->
            <assign-user-form
              button="Add Representative"
              :clientId="this.client.basic.id"
              :title="this.$t('client_representative_add')"
              :role-users="representatives"
              :resourceId="$route.params.id"
              :isEdit="isEdit"
              authLevel="client"
              @add="handleAddRepresentative"
              code="CLIENT_REPRESENTATIVE"
            />
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <b-spinner
          v-if="isEdit && firstLoading"
          class="mt-4 mb-4 ml-5"
        ></b-spinner>
        <base-table
          v-else
          class="table align-items-center table-flush"
          :thead-classes="'thead-light'"
          tbody-classes="list"
          :data="representatives"
        >
          <template slot="columns">
            <th>{{ $t('name') }}</th>
            <th>{{ $t('title') }}</th>
            <th>{{ $t('email') }}</th>
            <th>{{ $t('phone') }}</th>
            <th>{{ $t('status') }}</th>
            <th></th>
          </template>

          <template slot-scope="{ row }">
            <td scope="row">
              <span class="name mb-0 text-sm"
                >{{ row.firstName }} {{ row.lastName }}</span
              >
            </td>
            <td class="">
              {{ row.title }}
            </td>
            <td>
              {{ row.email }}
            </td>
            <td>
              {{ row.phone }}
            </td>
            <td>
              <app-badge
                :variant="
                  row.status.name.toLowerCase() === 'active'
                    ? 'success'
                    : 'secondary'
                "
              >
                {{
                  row.status.name.toLowerCase() === 'active'
                    ? $t('ACTIVE')
                    : $t('INACTIVE')
                }}
              </app-badge>
            </td>

            <td class="text-right" data-testid="rep-item">
              <update-user
                v-if="isEdit"
                title="Update Client Representative"
                authLevel="client"
                :member="row"
                :clientId="$route.params.id"
                class="mr-2"
                @updateNewUser="updateNewRepresentative"
              />
              <i
                class="fa fa-trash cursor-pointer"
                @click="deleteRepresentative(row)"
              ></i>
            </td>
          </template>
        </base-table>
      </div>
    </div>
    <b-row class="mt-5">
      <b-col>
        <base-button type="secondary" @click="$emit('back')" rounded
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          :disabled="representatives.length === 0"
          @click="handleNextClick"
          rounded
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AssignUserForm from '@/components/AssignUserForm';
import { validEmail } from '@/utils/validations';
import { mapState } from 'vuex';
import UpdateUser from '@/components/UpdateUser';
import { randomString } from '@/utils';

export default {
  components: {
    AssignUserForm,
    UpdateUser,
  },
  data() {
    return {
      firstLoading: true,
      representatives: [],
      newRep: {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        phone: '',
        status: '',
      },
      isEdit: this.$route.name === 'edit-client',
    };
  },
  props: {
    delete: Array,
  },
  computed: {
    isValidRep() {
      const { firstName, lastName, phone, email, title } = this.newRep;
      return !!firstName && !!lastName && !!phone && !!email && !!title;
    },
    emailStatus() {
      return this.newRep.email === '' ? null : validEmail(this.newRep.email);
    },
    ...mapState({
      client: ({ clients }) => clients.selectedClient,
    }),
  },
  created() {
    if (this.isEdit) {
      this.representatives = this.client.representatives;
      this.firstLoading = false;
    }
  },
  methods: {
    handleNextClick() {
      this.$emit('nextStep', this.representatives);
    },
    handleAddRepresentative(user) {
      //add temporary ID to find the user when update it
      const tempId = randomString();
      this.representatives = [...this.representatives, { ...user, tempId }];
      this.$toast.info(this.$t('toast_client_rep_added'));
    },
    updateNewRepresentative(user) {
      // check if the user is listed
      // compare with 'tempId' if user has it (user just added to the list)
      // otherwise use the 'id' (the user is already a representative of this client)
      let representativeIndex = this.representatives.findIndex(
        (representative) =>
          user.tempId
            ? user.tempId === representative.tempId
            : user.id === representative.id
      );

      if (representativeIndex !== -1) {
        this.representatives = [
          ...this.representatives.slice(0, representativeIndex),
          user,
          ...this.representatives.slice(representativeIndex + 1),
        ];
      }
      this.$store.commit(
        'experts/UPDATING_REPRESENTATIVE_DATA',
        this.representatives
      );
    },
    deleteRepresentative(user) {
      // If updating the client, add the 'userId' to the 'delete' array
      if (this.isEdit && user?.id?.length > 0 && !user?.tempId) {
        this.delete.push(user.id);
        this.$toast.info(this.$t('toast_client_rep_removed'));
      }

      this.representatives = this.representatives.filter(
        (representative) => representative.id !== user.id
      );
    },
  },
};
</script>

<style></style>
