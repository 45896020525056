<template>
  <div>
    <div class="card shadow my-5">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="mb-0">{{ $t('study_coordinators') }}</h4>
          </div>
          <div class="col text-right">
            <!-- Add Coordinator Modal -->
            <assign-user-form
              :button="$t('study_coordinator_add_short')"
              :clientId="isEdit ? study.clientId : $route.params.id"
              :title="$t('study_coordinator_add')"
              :role-users="coordinators"
              :resourceId="study.id"
              authLevel="study"
              :isEdit="isEdit"
              @add="handleAddCoordinator"
              code="STUDY_COORDINATOR"
            />
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <div class="text-center my-4" v-if="isEdit && firstLoading">
          <b-spinner></b-spinner>
        </div>
        <base-table
          v-else
          class="table align-items-center table-flush"
          :thead-classes="'thead-light'"
          tbody-classes="list"
          :data="coordinators"
        >
          <template slot="columns">
            <th>{{ $t('name') }}</th>
            <th>{{ $t('title') }}</th>
            <th>{{ $t('email') }}</th>
            <th>{{ $t('phone') }}</th>
            <th>{{ $t('status') }}</th>
            <th></th>
          </template>

          <template slot-scope="{ row }">
            <td scope="row">
              <span class="name mb-0 text-sm"
                >{{ row.firstName }} {{ row.lastName }}</span
              >
            </td>
            <td class="">
              {{ row.title }}
            </td>
            <td>
              {{ row.email }}
            </td>
            <td>
              {{ row.phone }}
            </td>
            <td>
              <app-badge
                :variant="
                  row.status.name === 'ACTIVE' ? 'success' : 'secondary'
                "
              >
                {{
                  row.status.name === 'ACTIVE' ? $t('ACTIVE') : $t('INACTIVE')
                }}
              </app-badge>
            </td>

            <td class="text-right">
              <update-user
                v-if="isEdit"
                title="Update Study Coordinator"
                authLevel="study"
                :member="row"
                :clientId="$route.params.id"
                class="mr-2"
                ref="updateUser"
                @updateNewUser="updateNewCoordinator"
              />
              <i
                class="fa fa-trash cursor-pointer"
                @click="deleteCoordinator(row)"
              ></i>
            </td>
          </template>
        </base-table>
      </div>
    </div>
    <b-row class="mt-5">
      <b-col>
        <base-button type="secondary" @click="$emit('back')"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="handleNextClick"
          :disabled="coordinators.length === 0"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AssignUserForm from '@/components/AssignUserForm';
import { mapState } from 'vuex';
import UpdateUser from '@/components/UpdateUser';
import { randomString } from '@/utils';

export default {
  components: {
    AssignUserForm,
    UpdateUser,
  },
  data() {
    return {
      firstLoading: true,
      coordinators: [],
      tempId: '',
      isEdit: this.$route.name.includes('edit'),
    };
  },
  props: {
    delete: Array,
  },
  computed: {
    ...mapState({
      study: ({ studies }) => studies.selectedStudy,
    }),
  },
  created() {
    if (this.isEdit) {
      this.coordinators = this.study.coordinators;
      this.firstLoading = false;
    }
  },
  methods: {
    handleNextClick() {
      this.$emit('nextStep', this.coordinators);
    },
    handleAddCoordinator(user) {
      //add temporary ID to find the user when update it
      const tempId = randomString();
      this.coordinators = [...this.coordinators, { ...user, tempId }];
      this.$toast.info(this.$t('toast_study_coord_updated'));
    },
    updateNewCoordinator(user) {
      // check if the user is listed
      // compare with 'tempId' if user has it (user just added to the list)
      // otherwise use the 'id' (the user is already a study coordinator)
      let coordinatorIndex = this.coordinators.findIndex((coordinator) =>
        user.tempId
          ? user.tempId === coordinator.tempId
          : user.id === coordinator.id
      );
      if (coordinatorIndex !== -1) {
        this.coordinators = [
          ...this.coordinators.slice(0, coordinatorIndex),
          user,
          ...this.coordinators.slice(coordinatorIndex + 1),
        ];
      }
    },
    deleteCoordinator(user) {
      // If updating the client, add the 'userId' to the 'delete' array
      if (this.isEdit && user?.id?.length > 0 && !user?.tempId) {
        this.delete.push(user.id);
        this.$toast.info(this.$t('toast_study_coord_removed'));
      }

      this.coordinators = this.coordinators.filter(
        (coordinator) => coordinator.id !== user.id
      );
    },
  },
};
</script>

<style></style>
