<template>
  <div class="mb-3">
    <p class="avenir-bold p-3 bg-white mb-0">
      {{ $t('require_attention') }}
    </p>
    <p v-if="firstLoading" class="p-3 bg-white">{{ $t('loading') }}</p>
    <participants-table
      v-else
      :participantListTableData="participants"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import ParticipantsTable from '@/components/Tables/ParticipantsTable.vue';
import { paginationDefaults } from '@/constants/pagination-constants';
import { mapActions, mapState } from 'vuex';
import listMixin from '@/mixins/listMixin';
import { participantListDataTableObjectMapper } from '@/object-mappers/participant.object-mapper';

export default {
  name: 'DashboardParticipantList',
  components: {
    ParticipantsTable,
  },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      participants: ({ participants }) =>
        participantListDataTableObjectMapper(participants.all),
      storePagination: ({ participants }) => participants.pagination,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('participants');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
      pagination: {
        pageSize: paginationDefaults.dashboardPageSize,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
    };
  },
  created() {
    this.getListData('participants');
  },
  methods: {
    ...mapActions({
      fetchListData: 'participants/fetchAllParticipants',
      setParticipantParams: 'participants/setParticipantParams',
    }),
    goToDetail(participant) {
      this.setParticipantParams({
        clientId: participant.clientId,
        studyId: participant.studyId,
        protocolId: participant.protocolId,
        siteId: participant.siteId,
        participantId: participant.participantId,
      });

      this.$emit('goToDetail', participant.participantDisplayId);
    },
  },
};
</script>
