<template>
  <div>
    <wizard-header
      title="Update Client"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard :currentStep="step" wizardType="clients"></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <basic-information
        :basic="client.basic"
        v-show="step === 0"
        @nextStep="step++"
      />
      <client-representatives
        v-show="step === 1"
        :delete="deleteRepresentatives"
        @nextStep="handleRepNext"
        @back="step--"
      />
      <contact-address
        v-show="step === 2"
        @nextStep="handleContactClick"
        @back="step--"
      />
      <invoice-address
        v-show="step === 3"
        @nextStep="handleInvoiceClick"
        @back="step--"
      />
      <overview
        :delete="deleteRepresentatives"
        :client="client"
        v-if="step === 4"
        @back="step--"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      title="Update Client"
      title-tag="h3"
      header-class="justify-content-center"
      footer-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center">
        {{ $t('exit_update_client') }}
      </p>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="$router.push({ name: 'client-details' })"
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BasicInformation from '@/components/Clients/BasicInformation';
import ClientRepresentatives from '@/components/Clients/ClientRepresentatives';
import ContactAddress from '@/components/Clients/ContactAddress';
import InvoiceAddress from '@/components/Clients/InvoiceAddress';
import Overview from '@/components/Clients/Overview';
import WizardHeader from '@/components/WizardHeader.vue';

import { validEmail } from '@/utils/validations';
import { mapState } from 'vuex';

export default {
  components: {
    BasicInformation,
    ClientRepresentatives,
    ContactAddress,
    InvoiceAddress,
    Overview,
    WizardHeader,
  },
  data() {
    return {
      showConfirmationModal: false,
      addingClient: false,
      step: 0,
      newRep: {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        phone: '',
      },
      showAddRep: false,
      deleteRepresentatives: [],
      client: {
        invoice: {
          address: '',
          city: '',
          countryId: '',
          postalCode: '',
        },
        basic: {
          programManagerId: '',
          clientTypeId: '',
          legalName: '',
          description: '',
        },
        representatives: [],
        contact: {
          website: '',
          email: '',
          phone: '',
          address: '',
          city: '',
          countryId: '',
          postalCode: '',
        },
      },
    };
  },
  created() {
    this.setBreadCrumbData({
      'edit-client': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },

        {
          text: this.selectedClient.basic.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.$t('client_update'),
          active: true,
        },
      ],
    });
  },

  mounted() {
    this.client = JSON.parse(JSON.stringify(this.selectedClient));
  },
  computed: {
    ...mapState({
      selectedClient: ({ clients }) => clients.selectedClient,
    }),
    emailStatus() {
      return this.newRep.email === '' ? null : validEmail(this.newRep.email);
    },
  },
  methods: {
    handleNextClick(payload) {
      this.client.basic = { ...payload };
      this.step++;
    },
    handleRepNext(payload) {
      this.client.representatives = [...payload];
      this.step++;
    },
    handleContactClick(payload) {
      this.client.contact = { ...payload };
      this.step++;
    },
    handleInvoiceClick(payload) {
      this.client.invoice = { ...payload };
      this.step++;
    },
  },
};
</script>

<style></style>
