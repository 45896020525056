var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.studyTasksList)?_c('b-row',{key:_vm.updateKey},_vm._l((_vm.studyTasksList),function(partner,partnerIndex){return _c('b-col',{key:partnerIndex},[_c('base-checkbox',{attrs:{"label":partner.name},on:{"input":function($event){return _vm.toggleTestsByPartner(partnerIndex)}},model:{value:(_vm.studyTasksList[partnerIndex].selectedPartner),callback:function ($$v) {_vm.$set(_vm.studyTasksList[partnerIndex], "selectedPartner", $$v)},expression:"studyTasksList[partnerIndex].selectedPartner"}},[_c('span',{staticClass:"partner-name"},[_vm._v(_vm._s(_vm.$t(partner.name)))]),_vm._l((_vm.studyTasksList[partnerIndex]
            .categories),function(category,categoryIndex){return _c('div',{key:categoryIndex},[(category.allTestsAlreadyInStudy)?_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"category-name category-name-checked"},[_c('i',{staticClass:"ni ni-check-bold custom-margin-i-cat"}),_vm._v(_vm._s(_vm.$t(category.name)))]),_vm._l((category.tests),function(task,tasksIndex){return _c('div',{key:tasksIndex},[(
                  _vm.studyTasksList[partnerIndex].categories[categoryIndex]
                    .tests[tasksIndex].alreadyInStudy
                )?_c('div',{key:tasksIndex,staticClass:"mt-3 task-container task-container-marked"},[_c('span',{staticClass:"mr-4 task-text"},[_c('i',{staticClass:"ni ni-check-bold custom-margin-i"}),_vm._v(_vm._s(_vm.$t(task.name)))]),_c('span',{staticClass:"task-text-languages custom-margin"},[_vm._v(_vm._s(_vm.capitalizeStrings(task.languages)))])]):_vm._e()])})],2):_c('base-checkbox',{staticClass:"mt-3",on:{"input":function($event){return _vm.toggleTestsByCategory(partnerIndex, categoryIndex)}},model:{value:(
              _vm.studyTasksList[partnerIndex].categories[categoryIndex]
                .selectedCategory
            ),callback:function ($$v) {_vm.$set(_vm.studyTasksList[partnerIndex].categories[categoryIndex]
                , "selectedCategory", $$v)},expression:"\n              studyTasksList[partnerIndex].categories[categoryIndex]\n                .selectedCategory\n            "}},[_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(_vm.$t(category.name)))]),_vm._l((category.tests),function(task,tasksIndex){return _c('div',{key:tasksIndex},[(
                  _vm.studyTasksList[partnerIndex].categories[categoryIndex]
                    .tests[tasksIndex].alreadyInStudy
                )?_c('div',{key:tasksIndex,staticClass:"mt-3 task-container"},[_c('span',{staticClass:"mr-4 task-text"},[_c('i',{staticClass:"ni ni-check-bold custom-margin-i"}),_vm._v(_vm._s(_vm.$t(task.name)))]),_c('span',{staticClass:"task-text-languages custom-margin"},[_vm._v(_vm._s(_vm.capitalizeStrings(task.languages)))])]):_c('base-checkbox',{staticClass:"mt-2",on:{"input":function (value) {
                    _vm.updateTaskList(
                      value,
                      task.id,
                      tasksIndex,
                      partnerIndex,
                      categoryIndex
                    );
                  }},model:{value:(
                  _vm.studyTasksList[partnerIndex].categories[categoryIndex]
                    .tests[tasksIndex].selectedTest
                ),callback:function ($$v) {_vm.$set(_vm.studyTasksList[partnerIndex].categories[categoryIndex]
                    .tests[tasksIndex], "selectedTest", $$v)},expression:"\n                  studyTasksList[partnerIndex].categories[categoryIndex]\n                    .tests[tasksIndex].selectedTest\n                "}},[_c('div',{staticClass:"task-container"},[_c('span',{staticClass:"task-text"},[_vm._v(_vm._s(_vm.$t(task.name)))]),_c('span',{staticClass:"task-text-languages"},[_vm._v(_vm._s(_vm.capitalizeStrings(task.languages)))])])])],1)})],2)],1)})],2)],1)}),1):_vm._e(),_c('b-row',{staticClass:"my-5"},[_c('b-col',[_c('base-button',{attrs:{"type":"secondary","data-testid":"back-button"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(_vm._s(_vm.$t('back'))+" ")]),_c('base-button',{attrs:{"data-testid":"next-button-task","type":"primary","disabled":_vm.isEdit ? false : _vm.disabled},on:{"click":_vm.goToOverview}},[_vm._v(_vm._s(_vm.$t('next'))+" "),_c('arrow-right')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }