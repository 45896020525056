<template>
  <div class="mb-3">
    <p class="avenir-bold p-3 bg-white mb-0">{{ $t('protocols') }}</p>
    <p v-if="firstLoading" class="p-3 bg-white">{{ $t('loading') }}</p>
    <protocols-table
      v-else
      :protocolsListTableData="protocols"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import ProtocolsTable from '@/components/Tables/ProtocolsTable.vue';
import { mapActions, mapState } from 'vuex';
import { paginationDefaults } from '@/constants/pagination-constants';
import listMixin from '@/mixins/listMixin';

export default {
  name: 'DashboardProtocolList',
  components: {
    ProtocolsTable,
  },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      protocols: ({ protocols }) => protocols.list,
      storePagination: ({ protocols }) => protocols.pagination,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('protocols');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
      pagination: {
        pageSize: paginationDefaults.dashboardPageSize,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
    };
  },
  created() {
    this.getListData('protocols');
    this.fetchResources();
  },
  methods: {
    ...mapActions({
      fetchListData: 'protocols/fetchProtocols',
      fetchResources: 'resources/dispatchFetchResources',
      setProtocolParams: 'protocols/setProtocolParams',
    }),
    goToDetail(protocol) {
      this.setProtocolParams({
        clientId: protocol.client.id,
        studyId: protocol.study.id,
        protocolId: protocol.id,
      });
      this.$emit('goToDetail', protocol.id);
    },
  },
};
</script>
