<template>
  <div class="bg-white shadow p-3">
    <b-row class="mx-0" :class="isActive && subSection ? 'border-bottom' : ''">
      <b-col
        class="px-0 d-flex justify-content-between align-items-center"
        :class="isActive && subSection ? 'pb-3' : 'pb-0'"
      >
        <p class="avenir-bold mb-0 text-lg">{{ title }}</p>
        <app-badge :variant="isActive ? 'danger' : 'blue'" class="px-3">
          {{ isActive ? 'Yes' : 'No' }}
        </app-badge>
      </b-col>
    </b-row>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'questions-section',
  props: {
    title: String,
    isActive: Boolean,
    subSection: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.text-lg {
  font-size: 1.125rem !important;
}
</style>
