<template>
  <div>
    <b-row v-if="studyTasksList" :key="updateKey">
      <b-col
        v-for="(partner, partnerIndex) in studyTasksList"
        :key="partnerIndex"
      >
        <base-checkbox
          v-model="studyTasksList[partnerIndex].selectedPartner"
          :label="partner.name"
          @input="toggleTestsByPartner(partnerIndex)"
          ><span class="partner-name">{{ $t(partner.name) }}</span>
          <div
            v-for="(category, categoryIndex) in studyTasksList[partnerIndex]
              .categories"
            :key="categoryIndex"
          >
            <div v-if="category.allTestsAlreadyInStudy" class="mt-3">
              <span class="category-name category-name-checked"
                ><i class="ni ni-check-bold custom-margin-i-cat"></i
                >{{ $t(category.name) }}</span
              >
              <div
                v-for="(task, tasksIndex) in category.tests"
                :key="tasksIndex"
              >
                <div
                  v-if="
                    studyTasksList[partnerIndex].categories[categoryIndex]
                      .tests[tasksIndex].alreadyInStudy
                  "
                  :key="tasksIndex"
                  class="mt-3 task-container task-container-marked"
                >
                  <span class="mr-4 task-text"
                    ><i class="ni ni-check-bold custom-margin-i"></i
                    >{{ $t(task.name) }}</span
                  >
                  <span class="task-text-languages custom-margin">{{
                    capitalizeStrings(task.languages)
                  }}</span>
                </div>
              </div>
            </div>

            <base-checkbox
              v-else
              v-model="
                studyTasksList[partnerIndex].categories[categoryIndex]
                  .selectedCategory
              "
              class="mt-3"
              @input="toggleTestsByCategory(partnerIndex, categoryIndex)"
            >
              <span class="category-name">{{ $t(category.name) }}</span>
              <div
                v-for="(task, tasksIndex) in category.tests"
                :key="tasksIndex"
              >
                <div
                  v-if="
                    studyTasksList[partnerIndex].categories[categoryIndex]
                      .tests[tasksIndex].alreadyInStudy
                  "
                  :key="tasksIndex"
                  class="mt-3 task-container"
                >
                  <span class="mr-4 task-text"
                    ><i class="ni ni-check-bold custom-margin-i"></i
                    >{{ $t(task.name) }}</span
                  >
                  <span class="task-text-languages custom-margin">{{
                    capitalizeStrings(task.languages)
                  }}</span>
                </div>

                <base-checkbox
                  v-else
                  class="mt-2"
                  v-model="
                    studyTasksList[partnerIndex].categories[categoryIndex]
                      .tests[tasksIndex].selectedTest
                  "
                  @input="
                    (value) => {
                      updateTaskList(
                        value,
                        task.id,
                        tasksIndex,
                        partnerIndex,
                        categoryIndex
                      );
                    }
                  "
                  ><div class="task-container">
                    <span class="task-text">{{ $t(task.name) }}</span>
                    <span class="task-text-languages">{{
                      capitalizeStrings(task.languages)
                    }}</span>
                  </div>
                </base-checkbox>
              </div>
            </base-checkbox>
          </div>
        </base-checkbox>
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          data-testid="next-button-task"
          type="primary"
          :disabled="isEdit ? false : disabled"
          @click="goToOverview"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'StudyTasksSelectionComponent',
  props: {
    studyTasksList: {
      type: Array,
    },
  },
  data() {
    return {
      updateKey: 0,
      disabled: true,
      isEdit: this.$route.name.includes('edit'),
    };
  },

  methods: {
    capitalizeStrings(array) {
      const capitalizedArray = array.map((str) => str.toUpperCase());
      return capitalizedArray.join(', ');
    },

    toggleTestsByCategory(partnerIndex, categoryIndex) {
      const tests =
        this.studyTasksList[partnerIndex].categories[categoryIndex].tests;

      const allSelected = tests.every((test) => test.selectedTest);
      if (allSelected) {
        tests.forEach((test) => {
          test.selectedTest = false;
        });
      } else {
        tests.forEach((test) => {
          test.selectedTest = true;
        });
      }
      this.updateKey += 1;
      this.checkCategories(partnerIndex);
    },

    updateTaskList(value, taskId, tasksIndex, partnerIndex, categoryIndex) {
      this.studyTasksList[partnerIndex].categories[categoryIndex].tests[
        tasksIndex
      ].selectedTest = value;
      const tests =
        this.studyTasksList[partnerIndex].categories[categoryIndex].tests;
      this.studyTasksList[partnerIndex].categories[
        categoryIndex
      ].selectedCategory = !tests.some((test) => !test.selectedTest);
      this.checkCategories(partnerIndex);
      this.updateKey += 1;
    },

    checkCategories(partnerIndex) {
      const categories = this.studyTasksList[partnerIndex].categories;
      const allSelected = categories.every((cat) => cat.selectedCategory);
      this.studyTasksList[partnerIndex].selectedPartner = !!allSelected;
      this.getSelectedTestsId();
    },

    toggleTestsByPartner(partnerIndex) {
      const categories = this.studyTasksList[partnerIndex].categories;
      const allSelected = categories.every((cat) => cat.selectedCategory);
      categories.forEach((category) => {
        const tests = category.tests;
        tests.forEach((test) => {
          test.selectedTest = !allSelected;
        });
        category.selectedCategory = !allSelected;
      });
      this.updateKey += 1;
      this.getSelectedTestsId();
    },

    getSelectedTestsId() {
      let testsByPartner = [];
      this.studyTasksList.forEach((partner) => {
        let partnerObj;
        partner.categories.forEach((category) => {
          category.tests.forEach((test) => {
            if (test.selectedTest) {
              if (!partnerObj) {
                partnerObj = {
                  partner: partner.name,
                  testNames: [],
                  testIds: [],
                };
                testsByPartner.push(partnerObj);
              }
              partnerObj.testNames.push(test.name);
              partnerObj.testIds.push(test.id);
            }
          });
        });
      });
      this.disabled = !testsByPartner.length;
      this.$store.commit('studies/SELECTED_TASKS', testsByPartner);
    },

    goToOverview() {
      this.$emit('nextStep');
    },
  },
};
</script>

<style scoped lang="scss">
.partner-name {
  font-size: large;
}
.category-name {
  font-size: 15px;
}
.task-container-marked {
  position: relative;
  left: 50px;
}
.task-text {
  font-size: 14px;
}
.task-text-languages {
  font-size: 11px;
}
.custom-margin {
  margin-left: 48px;
}
.custom-margin-i,
.custom-margin-i-cat {
  margin-right: 33px;
  position: relative;
  top: 10px;
  left: 5px;

  &.custom-margin-i-cat {
    top: 3px;
  }
}
</style>
